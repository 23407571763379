<template>
	<div>
		<div class="top-px-box">
			<div style="margin:20px;line-height: 30px;">方案数量:
			<span style="color: orangered;">{{tableData2.length}}</span>
			</div>
			<!-- <div class="px-dis">
				<div class="fc-px">分差排序</div>
				<div class="lr-px">利润排序</div>
			</div> -->
		</div>
		<el-table ref="multipleTable" border :header-cell-style="{textAlign: 'center',  }" stripe
			:data="tableData2" tooltip-effect="dark" style="width: 99%" 
			@selection-change="handleSelectionChange">
			<el-table-column align="center" type="selection" width="55">
			</el-table-column>
			<el-table-column align="center" prop="saishi" label="赛事(时间)" show-overflow-tooltip>
				<template slot-scope="scope">
					<div>{{scope.row.saishi.cc}}</div>
					<div>{{scope.row.saishi.date}}</div>
					<div>{{scope.row.saishi.jcName}}</div>
					<div>{{scope.row.saishi.hgName}}</div>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="jc" label="彩票(让胜负大小)" width="410">
				<template slot-scope="scope">
					<div></div>
					<div class="jc-bj" v-for="(item2,i) in scope.row.jc" :key="i">
						<div style="width: 150px;">{{item2['让球']}}</div>
						<div :style="item2['胜样式']?item2['胜样式']:bg2">{{item2['胜']}}</div>
						
						<div :style="item2['负样式']?item2['负样式']:bg2">{{item2['负']}}</div>
					</div>
		
					<div></div>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="hg" label="皇冠(让胜负大小)" width="410">
				<template slot-scope="scope">
					<div class="jc-bj" v-for="(item2,i) in scope.row.hg" :key="i">
						<div style="width: 150px;">{{item2['让球']}}</div>
						<div :style="item2['胜样式']?item2['胜样式']:bg2">{{item2['胜']}}</div>
						
						<div :style="item2['负样式']?item2['负样式']:bg2">{{item2['负']}}</div>
					</div>
					<!-- <div class="jc-bj" v-if="scope.row.hg.length<4">
						<div style="width: 90px;">-</div>
						<div style="width: 120px;">-</div>
						<div style="width: 120px;">-</div>
						<div style="width: 120px;">-</div>
					</div> -->
		
				</template>
			</el-table-column>
			<el-table-column align="center" prop="lr" label="利润率" width="80">
				<template slot-scope="scope">
					<div class="">分差:{{scope.row['分差']}} </div>
					<div class="">{{scope.row.profit}} </div>
					<div class="">{{scope.row['方案']}} </div>
					<el-button size="mini" type="success" @click="formula(scope.row)">计算</el-button>	
					
					
				</template>
			</el-table-column>
		</el-table>
		<!-- 计算器弹窗 -->
		<el-dialog 
		title="篮球单固计算器" 
		v-dialogDrag
		:modal="false"
		
		:visible.sync="dialogVisible" 
		width="40%" 
		:before-close="handleClose">
			<!-- 计算界面 -->
			<div class="counter-all">
				<div class="jc-region">
					<div style="text-align: center;">彩票</div>
					<div style="display: flex; height: 40px;line-height: 40px;margin-top: 15px;">
						<div class="mc-count">赔率</div>
						<el-input style="width: 115px;" size="small" v-model="countObj.jcpv1"></el-input>
						<el-input style="width: 115px;" disabled size="small" v-model="countObj.jcpv2"></el-input>
					</div>
					<div style="height: 30px;line-height: 30px;display: flex;">
						<div class="mc-count">投注</div>
						<el-input style="width: 115px;" size="small" v-model="countObj.jcjine1"></el-input>
						<el-input style="width: 115px;" size="small" disabled v-model="countObj.jcjine2"></el-input>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="mc-count">返点</div>
						<div class="fd">{{countObj.jcfd1}}</div>
						<div class="fd">{{countObj.jcfd2}}</div>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="mc-count">中奖金额</div>
						<div class="fd">{{countObj.jczjm1}}</div><div style="width: 100px;text-align: center;">{{countObj.jczjm2}}</div>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="mc-count">利润</div>
						<div class="fd" >{{countObj.lr}}</div>
						<div class="fd" v-show="countObj.jcfd2 !==''">{{countObj.lr}}</div>
						<div class="fd" v-show="countObj.jcfd2 ===''"></div>
					</div>
				</div>
				<div class="hg-one-region">
					<div style="text-align: center;">
						外场
					</div>
					<div style="margin-top: 19px;">
						<el-input style="width: 130px;" size="small" disabled v-model = "countObj.hgp1"></el-input><el-input
							style="width: 130px;" size="small" v-model="countObj.hgsf1"></el-input>
					</div>
					<div style="margin-top: 4px;">
						<el-input style="width: 130px;" size="small" disabled v-model="countObj.hgpjine1"></el-input><el-input
							style="width: 130px;" size="small" disabled v-model="countObj.hgsfjine1"></el-input>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="hgfd">{{countObj.hgfd1}}</div>
						<div class="hgfd">{{countObj.hgfd2}}</div>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="hgfd">{{countObj.hgzjm1}}</div>
						<div class="hgfd">{{countObj.hgzjm2}}</div>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="hgfd" v-show="countObj.hgfd1 !==''">{{countObj.lr}}</div>
						<div class="hgfd" v-show="countObj.hgfd1 ===''"></div>
						<div class="hgfd" v-show="countObj.hgfd2 !==''">{{countObj.lr}}</div>
					</div>
				</div>
				
			</div>
		
		
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">关闭</el-button>
				<el-button type="success" @click="formulaSub">计算</el-button>
			</span>
		</el-dialog>
		
	</div>
</template>

<script>
	export default {
		props:{
			setArr:{
				type:Array,
				required: false,
			}
		},
		data(){
			return{
				weekDay:["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
				pdweek:'',
				pdTmweek:'',
				oneHgping:false,
				countObj2:{},
				countObj:{
					jcpv1:'',
					
					jcjine1:'10000',
					
					jcfd1:'',
					
					jczjm1:'',
					
					hgp1:'',
					hgsf1:'',
					
					hgsfjine1:'',
					hgfd1:'',
					
					hgzjm1:'',
					
					mc1:'',
					f1:'',
					f2:'',
					lr:''
				},
				dialogVisible:false,
				tableData:[],
				tableData2:[],
				resultArr:[],
				ssArr:[],
				bg2:'width: 120px;'
			}
		},
		methods:{
			formula(item){
				this.dialogVisible = true;
				//this.countObj2 = JSON.parse(JSON.stringify(item.plobj));
				this.countObj = item.plobj;
				//return
				//console.log(item,'计算按钮点击了')
			},
			async formulaSub(){
				//this.countObj2 = JSON.parse(JSON.stringify(this.countObj))
				//console.log(this.countObj,111)
				const res = await this.$http({
					path: '/dg/formula/new',
					method: 'post',
					params: this.countObj
				});
				if(res.data.message == '无效的token'){
					return this.$message.error('登录失效，请重新登录')
				}
				//console.log(res.data,111)
				//return
				if(res.data.code == 200){
					let ceshi = this.ffym(res.data.result,this.cl)
					let result = JSON.parse(ceshi)
					this.countObj = result
				}
				//console.log(this.countObj,222)
			},
			async getNewData(){
				//return
				//console.log('篮球新全单固')
				this.resultArr = [];
				this.ssArr = [];
				this.tableData = [];
				var dt=new Date();
				this.pdweek = this.weekDay[dt.getDay()]
				const res = await this.$http({
					path: '/basket/dg/count/new',
					method: 'post',
					params: {
						"cf": this.setArr[0].value,
						"wf": this.setArr[1].value,
						"money": 100,
						"gl": this.setArr[2].value
					}
				});
				if(res.data.message == '无效的token'){
					return this.$message.error('登录失效，请重新登录')
				}
				if(res.data.code == '200'){
					//console.log(res.data)
					//return
					//this.tableData = res.data.result['赛事'];
					let ceshi = this.ffym(res.data.result,this.cl)
					let result = JSON.parse(ceshi)
					this.ssArr = result['赛事']
					this.resultArr = result['结果'];
					var i = 0;
					//return
					this.resultArr.forEach(item=>{
						i+=1;
						//console.log(item,111)
						//let lsArr = item['名称'].split('&');
						this.ssArr.forEach(item2=>{
							if(item['赛事编号'] == item2['saishi']['cc']){
								// item['jc'] = JSON.parse(JSON.stringify(item2['jc']));
								// item['hg'] = JSON.parse(JSON.stringify(item2['hg']));
								item['saishi'] = JSON.parse(JSON.stringify(item2['saishi']));
								
							}
							
						})
						
						item['方案'] = '方案'+i;
						item['profit'] = (item['利润率']*100).toFixed(2)+'%';
						let lsArr = item['名称'].split('&');
						let jc1 = '无';
						let jc2 = '无';
						let hg1 = '无';
						let hg2 = '无';
						let jcpv1 = '';
						let jcpv2 = '';
						let jcjine1 = '';
						let jcjine2 = '';
						let jcfd1 = '';
						let jcfd2 = '';
						let jczjm1 = '';
						let jczjm2 = '';
						let hgp1 = '';
						let hgsf1 = '';
						//let mc1 = item['名称'];
						let mc1 = '胜&客+0.5'
						//jcpv1 = item['竞彩赔率'];
						if(lsArr[0].indexOf('|')!==-1){
							let jcspArr = lsArr[0].split('|')
							if(jcspArr[0] == '胜'){
								jc1 = '胜'
							}
							if(jcspArr[0] == '平'){
								jc1 = '平'
								//jcpv2 = item['平赔率']
							}
							if(jcspArr[0] == '负'){
								jc1 = '负'
							}
							if(jcspArr[1] == '胜'){
								jc2 = '胜'
								jcpv2 = item['平赔率']
							}
							if(jcspArr[1] == '平'){
								jc2 = '平'
							}
							if(jcspArr[1] == '负'){
								jc2 = '负'
							}
						}else{
							if(lsArr[0] == '胜'){
								jc1 = '胜'
							}
							if(lsArr[0] == '平'){
								jc1 = '平'
							}
							if(lsArr[0] == '负'){
								jc1 = '负'
							}
						}
						
						//hg
						if(lsArr[1].indexOf('|')!==-1){
							let hgspArr = lsArr[1].split('|')
							if(hgspArr[0] == '胜' || hgspArr[0].indexOf('主')!==-1){
								hg1 = '胜'
							}
							if(hgspArr[0] == '平'){
								hg1 = '平'
							}
							if(hgspArr[0] == '负' || hgspArr[0].indexOf('客')!==-1){
								hg1 = '负'
							}
							if(hgspArr[1] == '胜' || hgspArr[1].indexOf('主')!==-1){
								hg2 = '胜'
							}
							if(hgspArr[1] == '平'){
								hg2 = '平'
							}
							if(hgspArr[1] == '负' || hgspArr[1].indexOf('客')!==-1){
								hg2 = '负'
							}
						}else{
							if(lsArr[1] == '胜' || lsArr[1].indexOf('主')!==-1){
								hg1 = '胜'
							}
							if(lsArr[1] == '平'){
								hg1 = '平'
							}
							if(lsArr[1] == '负'  || lsArr[1].indexOf('客')!==-1){
								hg1 = '负'
							}
						}
						item['jc'] = [];
						item['hg'] = [];
						if(item['类型'] == '胜负'){
							if(jc1 == '胜'){
								item['jc'].push({
									'让球':'胜负',
									'胜':item['竞彩胜'],
									'负':item['竞彩负'],
									'胜样式':'width: 120px;background:pink;'
								})
							}
							if(jc1 == '负'){
								item['jc'].push({
									'让球':'胜负',
									'胜':item['竞彩胜'],
									'负':item['竞彩负'],
									'负样式':'width: 120px;background:pink;'
								})
							}
							if(hg1 == '胜'){
								item['hg'].push({
									'让球':'全场胜负',
									'胜':item['皇冠胜'],
									'负':item['皇冠负'],
									'胜样式':'width: 120px;background:pink;'
								})
							}
							if(hg1 == '负'){
								item['hg'].push({
									'让球':'全场胜负',
									'胜':item['皇冠胜'],
									'负':item['皇冠负'],
									'负样式':'width: 120px;background:pink;'
								})
							}
						}
						if(item['类型'] == '让分胜负'){
							if(jc1 == '胜'){
								item['jc'].push({
									'让球':`让分胜负(${item['竞彩让球']})`,
									'胜':item['竞彩胜'],
									'负':item['竞彩负'],
									'胜样式':'width: 120px;background:pink;'
								})
							}
							if(jc1 == '负'){
								item['jc'].push({
									'让球':`让分胜负(${item['竞彩让球']})`,
									'胜':item['竞彩胜'],
									'负':item['竞彩负'],
									'负样式':'width: 120px;background:pink;'
								})
							}
							if(hg1 == '胜'){
								item['hg'].push({
									'让球':`全场让球(${item['球数']})`,
									'胜':item['皇冠胜'],
									'负':item['皇冠负'],
									'胜样式':'width: 120px;background:pink;'
								})
							}
							if(hg1 == '负'){
								item['hg'].push({
									'让球':`全场让球(${item['球数']})`,
									'胜':item['皇冠胜'],
									'负':item['皇冠负'],
									'负样式':'width: 120px;background:pink;'
								})
							}
						}
						if(item['类型'] == '大小分'){
							if(jc1 == '胜'){
								item['jc'].push({
									'让球':`大小分(${item['竞彩让球']})`,
									'胜':item['竞彩胜'],
									'负':item['竞彩负'],
									'胜样式':'width: 120px;background:pink;'
								})
							}
							if(jc1 == '负'){
								item['jc'].push({
									'让球':`大小分(${item['竞彩让球']})`,
									'胜':item['竞彩胜'],
									'负':item['竞彩负'],
									'负样式':'width: 120px;background:pink;'
								})
							}
							if(hg1 == '胜'){
								item['hg'].push({
									'让球':`全场大小(${item['球数']})`,
									'胜':item['皇冠胜'],
									'负':item['皇冠负'],
									'胜样式':'width: 120px;background:pink;'
								})
							}
							if(hg1 == '负'){
								item['hg'].push({
									'让球':`全场大小(${item['球数']})`,
									'胜':item['皇冠胜'],
									'负':item['皇冠负'],
									'负样式':'width: 120px;background:pink;'
								})
							}
						}
						// item['jc'].forEach(item3=>{
						// 	if(item3['让球'] == item['竞彩让球']){
						// 		if(jc1 == '胜'){
						// 			item3['胜样式'] = 'width: 120px;background:pink;'
						// 		}
						// 		if(jc1 == '平'){
						// 			item3['平样式'] = 'width: 120px;background:pink;'
						// 		}
						// 		if(jc1 == '负'){
						// 			item3['负样式'] = 'width: 120px;background:pink;'
						// 		}
						// 		if(jc2 == '胜'){
						// 			item3['胜样式'] = 'width: 120px;background:pink;'
						// 		}
						// 		if(jc2 == '平'){
						// 			item3['平样式'] = 'width: 120px;background:pink;'
						// 		}
						// 		if(jc2 == '负'){
						// 			item3['负样式'] = 'width: 120px;background:pink;'
						// 		}
						// 	}
						// })
						// if(item['名称'] !== '平&主|客-0.25' && item['名称'] !== '平&主|客-0.5' && item['名称'] !== '平&主|客0' && item['名称'] !== '平&主|客+0.25' ){
						// 	item['hg'].forEach(item3=>{
						// 		if(item3['让球'] == '胜平负' && hg1 == '平'){
						// 			item3['平样式'] = 'width: 120px;background:pink;'
						// 		}
						// 		if(item3['让球'] == item['球数']){
						// 			if(hg1 == '胜'){
						// 				item3['胜样式'] = 'width: 120px;background:pink;'
						// 			}
						// 			// if(hg1 == '平'){
						// 			// 	item3['平样式'] = 'width: 120px;background:pink;'
						// 			// }
						// 			if(hg1 == '负'){
						// 				item3['负样式'] = 'width: 120px;background:pink;'
						// 			}
						// 			if(hg2 == '胜'){
						// 				item3['胜样式'] = 'width: 120px;background:pink;'
						// 			}
						// 			// if(hg2 == '平'){
						// 			// 	item3['平样式'] = 'width: 120px;background:pink;'
						// 			// }
						// 			if(hg2 == '负'){
						// 				item3['负样式'] = 'width: 120px;background:pink;'
						// 			}
						// 		}
						// 	})
						// }else{
						// 	item['hg'].forEach(item3=>{
						// 		if(item3['让球'] == '胜平负' && hg1 == '胜'){
						// 			item3['胜样式'] = 'width: 120px;background:pink;'
						// 		}
						// 		if(item3['让球'] == '胜平负' && hg1 == '负'){
						// 			item3['负样式'] = 'width: 120px;background:pink;'
						// 		}
						// 		if(item3['让球'] == item['球数']){
									
						// 			if(hg2 == '胜'){
						// 				item3['胜样式'] = 'width: 120px;background:pink;'
						// 			}
						// 			if(hg2 == '负'){
						// 				item3['负样式'] = 'width: 120px;background:pink;'
						// 			}
						// 		}
						// 	})
						// }
						
						// if(item['名称'] == '胜|平&负' || item['名称'] == '负|平&胜' || item['名称'] == '胜|平&客-0.25' || item['名称'] == '胜|平&客-0.5' || item['名称'] == '胜|平&客0' || item['名称'] == '胜|平&客+0.25' || item['名称'] == '负|平&主-0.25' || item['名称'] == '负|平&主-0.5' || item['名称'] == '负|平&主0' || item['名称'] == '负|平&主+0.25'){
						// 	jcpv1 = item['竞彩赔率'];
						// 	jcpv2 = item['平赔率'];
						// 	hgsf1 = item['皇冠赔率'];
							
						// }
						// if(item['名称'] == '胜&平|负' || item['名称'] == '负&平|胜' || item['名称'] == '胜&平|客-0.25' || item['名称'] == '胜&平|客0' || item['名称'] == '胜&平|客-0.5' || item['名称'] == '胜&平|客+0.25' || item['名称'] == '负&平|主-0.25' || item['名称'] == '负&平|主0' || item['名称'] == '负&平|主-0.5' || item['名称'] == '负&平|主+0.25'){
						// 	jcpv1 = item['竞彩赔率'];
						// 	hgp1 = item['平赔率'];
						// 	hgsf1 = item['皇冠赔率'];
						// }
						// if(item['名称'] == '平&胜|负' || item['名称'] == '平&主|客-0.25' || item['名称'] == '平&主|客-0.5' || item['名称'] == '平&主|客0' || item['名称'] == '平&主|客+0.25' ){
						// 	jcpv1 = item['竞彩平赔率'];
						// 	hgp1 = item['皇冠主赔率'];
						// 	hgsf1 = item['皇冠客赔率'];
						// }
						
						// if(item['名称'] == '胜&客+0.5' || item['名称'] == '负&主+0.5' || item['名称'] == '胜&客+0.5' || item['名称'] == '负&主+0.5'){
						// 	jcpv1 = item['竞彩赔率'];
						// 	hgsf1 = item['皇冠赔率'];
						// }
						jcpv1 = item['竞彩赔率'];
						hgsf1 = item['皇冠赔率'];
						let plOne = this.setArr[0].plOne;
						let plTwo = this.setArr[0].plTwo;
						let plZjz = (Number(plOne)+Number(plTwo))/2
						let jcplA = jcpv1;
						let jcplB = jcpv2;
						let hgplA = hgp1;
						let hgplB = hgsf1;
						if(jcplA == ""){
							jcplA = plZjz
						}
						if(jcplB == ""){
							jcplB = plZjz
						}
						if(hgplA == ""){
							hgplA = plZjz
						}
						if(hgplB == ""){
							hgplB = plZjz
						}
						let plobj = {
							jcpv1:jcpv1,
							jcpv2:jcpv2,
							jcjine1:'10000',
							jcjine2:'',
							jcfd1:'',
							jcfd2:'',
							jczjm1:'',
							jczjm2:'',
							hgp1:hgp1,
							hgsf1:hgsf1,
							hgpjine1:'',
							hgsfjine1:'',
							hgfd1:'',
							hgfd2:'',
							hgzjm1:'',
							hgzjm2:'',
							mc1:mc1,
							f1:this.setArr[0].value,
							f2:this.setArr[1].value,
							lr:''
						}
						item['plobj'] = plobj;
						// if(jcplA>= plOne && jcplA<=plTwo && jcplB>= plOne && jcplB<=plTwo && hgplA>= plOne && hgplA<=plTwo  && hgplB>= plOne && hgplB<=plTwo){
						// 	//this.tableData.push(item)
						// 	this.tableData.push(JSON.parse(JSON.stringify(item)))
						// }
						this.tableData.push(JSON.parse(JSON.stringify(item)))
						
					})
				}
				this.tableData = this.tableData.filter((item)=>{
					return this.setArr[0].ssgl.indexOf(item.saishi.cc) ==-1;
				})
				if(this.setArr[0].guolv == 'today'){
					this.tableData = this.tableData.filter((item) => {
						//console.log(item)
					  return item.saishi.cc.indexOf(this.pdweek) !==-1;
					});
				}
				if(this.setArr[0].guolv == 'tm'){
					this.tableData = this.tableData.filter((item) => {
						//console.log(item)
					  return item.saishi.cc.indexOf(this.pdweek) !==-1 || item.saishi.cc.indexOf(this.pdTmweek) !==-1;
					});
				}
				this.tableData2 = this.tableData
				this.$emit('newdg',this.tableData.length);
				//console.log(res.data,'全新单固页面')
			},
			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			handleClose(done) {
				done();
				// this.$confirm('确认关闭？').then(_ => {
				// 	done();
				// }).catch(_ => {});
			},
		},
		beforeMount() {
			var dt=new Date();
			let dtTm = dt.getDay()+1;
			if(dtTm == 7){
				dtTm = 0;
			}
			this.pdTmweek = this.weekDay[dtTm]
			this.pdweek = this.weekDay[dt.getDay()]
			this.getNewData()
		
		}
	}
</script>

<style scoped> 
.mc-count{
	width: 60px;
	
}
.fd{
	width: 100px;
	margin-left: 15px;
	/* padding-left: 15px; */
	
	/* text-align: center; */
}
.hgfd{
	width: 115px;
	margin-left: 15px;
	
	/* text-align: center; */
}
.jc-bj {
		display: flex;
		justify-content: space-around;
	}
.counter-all {
		border-top: 2px solid #0DA969;
		display: flex;
		justify-content: space-between;
		width: 99%;
		font-size: 10px;

		padding-top: 10px;
	}

	.jc-region {
		width: 350px;
	}
	.hg-one-region{
		width: 350px;
	}
	.top-px-box{
		display: flex;
		justify-content: space-between;
		line-height: 30px;
	}
	.px-dis{
		display: flex;
		
		margin: 20px;
	}
	.fc-px{
		width: 120px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border-radius: 8px;
		background-color: #FF6C37;
		color: #FFFFFF;
		margin-right: 20px;
	}
	.lr-px{
		width: 120px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border-radius: 8px;
		border: 1px solid #FF6C37;
		color: #FF6C37;
		
	}
</style>