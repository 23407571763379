<template>
	<div>
		<div style="margin:20px;line-height: 30px;">方案数量:
		<span style="color: orangered;">{{tableData2.length}}</span>
		</div>
		<el-table ref="multipleTable" border :header-cell-style="{textAlign: 'center',  }" stripe
			:data="tableData2" tooltip-effect="dark" style="width: 99%" 
			@selection-change="handleSelectionChange">
			<el-table-column align="center" type="selection" width="55">
			</el-table-column>
			<el-table-column align="center" prop="saishi" label="赛事(时间)" show-overflow-tooltip>
				<template slot-scope="scope">
					<div>{{scope.row.saishi.cc}}</div>
					<div>{{scope.row.saishi.date}}</div>
					<div>{{scope.row.saishi.jcName}}</div>
					<div>{{scope.row.saishi.hgName}}</div>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="jc" label="彩票(半全场)" width="380">
				<template slot-scope="scope">
					<div></div>
					<!-- {{scope.row.jc[0]}} -->
					<div class="jc-bqc-box">
						<div class="bqc-s-all" :style="scope.row.jc[0]['胜样式']?scope.row.jc[0]['胜样式']:''">
							<div >胜胜:{{scope.row.jc[0]['胜胜']}}</div>
							<div>平胜:{{scope.row.jc[0]['平胜']}}</div>
							<div>负胜:{{scope.row.jc[0]['负胜']}}</div>
						</div>
						<div class="bqc-p-all" :style="scope.row.jc[0]['平样式']?scope.row.jc[0]['平样式']:''">
							<div>胜平:{{scope.row.jc[0]['胜平']}}</div>
							<div>平平:{{scope.row.jc[0]['平平']}}</div>
							<div>负平:{{scope.row.jc[0]['负平']}}</div>
						</div>
						<div class="bqc-f-all" :style="scope.row.jc[0]['负样式']?scope.row.jc[0]['负样式']:bg2">
							<div>胜负:{{scope.row.jc[0]['胜负']}}</div>
							<div>平负:{{scope.row.jc[0]['平负']}}</div>
							<div>负负:{{scope.row.jc[0]['负负']}}</div>
						</div>
					</div>
					
					<!-- <div class="jc-bj" v-for="(item2,i) in scope.row.jc" :key="i">
						<div style="width: 90px;">{{item2['让球']}}</div>
						<div :style="item2['胜样式']?item2['胜样式']:bg2">{{item2['胜']}}</div>
						<div :style="item2['平样式']?item2['平样式']:bg2">{{item2['平']}}</div>
						<div :style="item2['负样式']?item2['负样式']:bg2">{{item2['负']}}</div>
					</div> -->
		
					<div></div>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="hg" label="皇冠(让胜平负)" width="380">
				<template slot-scope="scope">
					<div class="jc-bj" v-for="(item2,i) in scope.row.hg" :key="i">
						<div style="width: 90px;">{{item2['让球']}}</div>
						<div :style="item2['胜样式']?item2['胜样式']:bg2">{{item2['胜']}}</div>
						<div :style="item2['平样式']?item2['平样式']:bg2">{{item2['平']}}</div>
						<div :style="item2['负样式']?item2['负样式']:bg2">{{item2['负']}}</div>
					</div>
					<div class="jc-bj" v-if="scope.row.hg.length<4">
						<div style="width: 90px;">-</div>
						<div style="width: 120px;">-</div>
						<div style="width: 120px;">-</div>
						<div style="width: 120px;">-</div>
					</div>
		
				</template>
			</el-table-column>
			<el-table-column align="center" prop="lr" label="利润率" width="80">
				<template slot-scope="scope">
					<div class="">{{scope.row.profit}} </div>
					<div class="">{{scope.row['方案']}} </div>
					<el-button size="mini" type="success" @click="formula(scope.row)">计算</el-button>	
					
					
				</template>
			</el-table-column>
		</el-table>
		<!-- 计算器弹窗 -->
		<el-dialog 
		title="投注计算器" 
		v-dialogDrag
		:modal="false"
		
		:visible.sync="dialogVisible" 
		width="60%" 
		:before-close="handleClose">
			<!-- 计算界面 -->
			<div class="counter-all">
				<div class="jc-region">
					<div style="text-align: center;">竞彩</div>
					<div style="display: flex; height: 40px;line-height: 40px;margin-top: 15px;">
						<div class="mc-count">赔率</div>
						<el-input style="width: 70px;" size="small" v-model="countObj.jchh1"></el-input>
						<el-input style="width: 70px;" size="small" v-model="countObj.jchh2"></el-input>
						<el-input style="width: 70px;" size="small" v-model="countObj.jchh3"></el-input>
						<el-input style="width: 70px;" size="small" v-model="countObj.jcdd1"></el-input>
						<el-input style="width: 70px;" size="small" v-model="countObj.jcdd2"></el-input>
						<el-input style="width: 70px;" size="small" v-model="countObj.jcdd3"></el-input>
					</div>
					<div style="height: 30px;line-height: 30px;display: flex;">
						<div class="mc-count">投注</div>
						<el-input style="width: 70px;" size="small" disabled v-model="countObj.jchhje1"></el-input>
						<el-input style="width: 70px;" size="small" disabled v-model="countObj.jchhje2"></el-input>
						<el-input style="width: 70px;" size="small" disabled v-model="countObj.jchhje3"></el-input>
						<el-input style="width: 70px;" size="small" disabled v-model="countObj.jcddje1"></el-input>
						<el-input style="width: 70px;" size="small" disabled v-model="countObj.jcddje2"></el-input>
						<el-input style="width: 70px;" size="small" disabled v-model="countObj.jcddje3"></el-input>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="mc-count">返点</div>
						<div class="fd">{{countObj.jcfd1}}</div>
						<div class="fd2">{{countObj.jcfd2}}</div>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="mc-count">奖金</div>
						<div class="fd">{{countObj.jczjm1}}</div>
						<div class="fd2">{{countObj.jczjm2}}</div>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="mc-count">利润</div>
						<!-- <div class="fd" >{{countObj.lr}}</div> -->
						<div class="fd" v-show="countObj.jcfd1 !==''">{{countObj.lr}}</div>
						<div class="fd" v-show="countObj.jcfd1 ===''"></div>
						<div class="fd2" v-show="countObj.jcfd2 !==''">{{countObj.lr}}</div>
						<div class="fd2" v-show="countObj.jcfd2 ===''"></div>
					</div>
				</div>
				<div class="hg-one-region">
					<div style="text-align: center;">
						外场
					</div>
					<div style="margin-top: 19px;">
						<el-input style="width: 130px;" size="small" :disabled="oneHgping" v-model = "countObj.hgp1"></el-input><el-input
							style="width: 130px;" size="small" v-model="countObj.hgsf1"></el-input>
					</div>
					<div style="margin-top: 4px;">
						<el-input style="width: 130px;" size="small" disabled v-model="countObj.hgpjine1"></el-input><el-input
							style="width: 130px;" size="small" disabled v-model="countObj.hgsfjine1"></el-input>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="hgfd">{{countObj.hgfd1}}</div>
						<div class="hgfd">{{countObj.hgfd2}}</div>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="hgfd">{{countObj.hgzjm1}}</div>
						<div class="hgfd">{{countObj.hgzjm2}}</div>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="hgfd" v-show="countObj.hgfd1 !==''">{{countObj.lr}}</div>
						<div class="hgfd" v-show="countObj.hgfd1 ===''"></div>
						<div class="hgfd" v-show="countObj.hgfd2 !==''">{{countObj.lr}}</div>
					</div>
				</div>
				
			</div>
		
		
			<span slot="footer" class="dialog-footer">
				<div style="line-height: 40px;margin-right: 30px;">
					投注金额(竞彩)
					<el-input  style="width: 100px; line-height: 40px;text-align: center;" size="small" v-model = "countObj.jcjine1"></el-input>
				</div>
				
				<el-button @click="dialogVisible = false">关闭</el-button>
				<el-button type="success" @click="formulaSub">计算</el-button>
			</span>
		</el-dialog>
		
	</div>
</template>

<script>
	export default {
		props:{
			setArr:{
				type:Array,
				required: false,
			}
		},
		data(){
			return{
				weekDay:["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
				pdweek:'',
				pdTmweek:'',
				oneHgping:false,
				countObj2:{},
				countObj:{
					jcpv1:'',
					jcpv2:'',
					jcjine1:'10000',
					jcjine2:'',
					jcfd1:'',
					jcfd2:'',
					jczjm1:'',
					jczjm2:'',
					hgp1:'',
					hgsf1:'',
					hgpjine1:'',
					hgsfjine1:'',
					hgfd1:'',
					hgfd2:'',
					hgzjm1:'',
					hgzjm2:'',
					mc1:'',
					f1:'',
					f2:'',
					lr:''
				},
				dialogVisible:false,
				tableData:[],
				tableData2:[],
				resultArr:[],
				ssArr:[],
				bg2:'width: 120px;'
			}
		},
		methods:{
			formula(item){
				this.dialogVisible = true;
				//this.countObj2 = JSON.parse(JSON.stringify(item.plobj));
				this.countObj = item.plobj;
				//console.log(this.countObj,188)
				//console.log(item,'计算按钮点击了')
			},
			calBqc(a,b,c){
				return Number((1/(1/a+1/b+1/c)).toFixed(2))
			},
			youhua(a,b,c){
				let zba = (1/a)/((1/a)+(1/b)+(1/c))
				let zbb = (1/b)/((1/a)+(1/b)+(1/c))
				let zbc = (1/c)/((1/a)+(1/b)+(1/c))
				let zbObj = {
					bla:zba,
					blb:zbb,
					blc:zbc
				}
				return zbObj
			},
			async formulaSub(){
				
				this.countObj.jcpv1 = this.calBqc(this.countObj.jchh1,this.countObj.jchh2,this.countObj.jchh3)
				if(this.countObj.jcdd1 != ''){
					this.countObj.jcpv2 = this.calBqc(this.countObj.jcdd1,this.countObj.jcdd2,this.countObj.jcdd3)
				}
				
				if(this.countObj.mc1 == '平&胜|负' || this.countObj.mc1 == '平&主|客-0.25' || this.countObj.mc1 == '平&主|客-0.5' || this.countObj.mc1 == '平&主|客0' || this.countObj.mc1 == '平&主|客+0.25'){
					this.countObj.jcpv1 = this.calBqc(this.countObj.jcdd1,this.countObj.jcdd2,this.countObj.jcdd3)
					// this.countObj.jchh1 = this.countObj.jcdd1;
					// this.countObj.jchh2 = this.countObj.jcdd2;
					// this.countObj.jchh3 = this.countObj.jcdd3;
					// this.countObj.jcdd1 = ""
					// this.countObj.jcdd2 = ""
					// this.countObj.jcdd3 = ""
					// this.countObj.jcpv2 = ""
				}
				
				
				
				
				//this.countObj2 = JSON.parse(JSON.stringify(this.countObj))
				//console.log(this.countObj,111)
				const res = await this.$http({
					path: '/dg/formula/new',
					method: 'post',
					params: this.countObj
				});
				if(res.data.message == '无效的token'){
					return this.$message.error('登录失效，请重新登录')
				}
				let fhData = null;
				if(res.data.code == 200){
					let ceshi = this.ffym(res.data.result,this.cl)
					let result = JSON.parse(ceshi)
					fhData = result;
					//console.log(fhData,'返回数据')
					let zbObj1 = this.youhua(Number(fhData.jchh1),Number(fhData.jchh2),Number(fhData.jchh3))
					fhData.jchhje1 = (fhData.jcjine1*zbObj1.bla).toFixed(2)
					fhData.jchhje2 = (fhData.jcjine1*zbObj1.blb).toFixed(2)
					fhData.jchhje3 = (fhData.jcjine1*zbObj1.blc).toFixed(2)
					if(fhData.jcjine2 !=""){
						let zbObj2 = this.youhua(Number(fhData.jcdd1),Number(fhData.jcdd2),Number(fhData.jcdd3))
						fhData.jcddje1 = (fhData.jcjine2*zbObj2.bla).toFixed(2)
						fhData.jcddje2 = (fhData.jcjine2*zbObj2.blb).toFixed(2)
						fhData.jcddje3 = (fhData.jcjine2*zbObj2.blc).toFixed(2)
					}
					if(fhData.mc1 == '平&胜|负' || fhData.mc1 == '平&主|客-0.25' || fhData.mc1 == '平&主|客-0.5' || fhData.mc1 == '平&主|客0' || fhData.mc1 == '平&主|客+0.25'){
						let zbObj2 = this.youhua(Number(fhData.jcdd1),Number(fhData.jcdd2),Number(fhData.jcdd3))
						fhData.jcddje1 = (fhData.jcjine1*zbObj2.bla).toFixed(2)
						fhData.jcddje2 = (fhData.jcjine1*zbObj2.blb).toFixed(2)
						fhData.jcddje3 = (fhData.jcjine1*zbObj2.blc).toFixed(2)
						
						fhData.jcfd2 = fhData.jcfd1;
						fhData.jczjm2 = fhData.jczjm1;
						fhData.jchhje1 = ""
						fhData.jchhje2 = ""
						fhData.jchhje3 = ""
						fhData.jcfd1 = ""
						fhData.jczjm1 = ""
					}
					this.countObj = fhData
					//console.log(fhData,1212)
					//this.countObj = res.data.result
				}
				//console.log(this.countObj,222)
			},
			async getNewData(){
				this.resultArr = [];
				this.ssArr = [];
				this.tableData = [];
				var dt=new Date();
				let dtTm = dt.getDay()+1;
				if(dtTm == 7){
					dtTm = 0;
				}
				this.pdTmweek = this.weekDay[dtTm]
				this.pdweek = this.weekDay[dt.getDay()]
				const res = await this.$http({
					path: '/bqc/count',
					method: 'post',
					params: {
						"cf": this.setArr[0].value,
						"wf": this.setArr[1].value,
						"money": 100,
						"gl": this.setArr[2].value
					}
				});
				if(res.data.message == '无效的token'){
					return this.$message.error('登录失效，请重新登录')
				}
				if(res.data.code == '200'){
					//console.log(res.data)
					
					//this.tableData = res.data.result['赛事'];
					let ceshi = this.ffym(res.data.result,this.cl)
					let result = JSON.parse(ceshi)
					this.ssArr = result['赛事']
					this.resultArr = result['结果'];
					var i = 0;
					//console.log(this.resultArr,555)
					this.resultArr.forEach(item=>{
						i+=1;
						//let lsArr = item['名称'].split('&');
						this.ssArr.forEach(item2=>{
							if(item['赛事编号'] == item2['saishi']['cc']){
								item['jc'] = JSON.parse(JSON.stringify(item2['jc']));
								item['hg'] = JSON.parse(JSON.stringify(item2['hg']));
								item['saishi'] = JSON.parse(JSON.stringify(item2['saishi']));
								
							}
							
						})
						
						item['方案'] = '方案'+i;
						item['profit'] = (item['利润率']*100).toFixed(2)+'%';
						let lsArr = item['名称'].split('&');
						let jc1 = '无';
						let jc2 = '无';
						let hg1 = '无';
						let hg2 = '无';
						let jcpv1 = '';
						let jcpv2 = '';
						let jchh1 = '';
						let jchh2 = '';
						let jchh3 = '';
						let jcdd1 = '';
						let jcdd2 = '';
						let jcdd3 = '';
						
						let jcjine1 = '';
						let jcjine2 = '';
						let jcfd1 = '';
						let jcfd2 = '';
						let jczjm1 = '';
						let jczjm2 = '';
						let hgp1 = '';
						let hgsf1 = '';
						let mc1 = item['名称'];
						//jcpv1 = item['竞彩赔率'];
						if(lsArr[0].indexOf('|')!==-1){
							let jcspArr = lsArr[0].split('|')
							if(jcspArr[0] == '胜'){
								jc1 = '胜'
							}
							if(jcspArr[0] == '平'){
								jc1 = '平'
								//jcpv2 = item['平赔率']
							}
							if(jcspArr[0] == '负'){
								jc1 = '负'
							}
							if(jcspArr[1] == '胜'){
								jc2 = '胜'
								jcpv2 = item['平赔率']
							}
							if(jcspArr[1] == '平'){
								jc2 = '平'
							}
							if(jcspArr[1] == '负'){
								jc2 = '负'
							}
						}else{
							if(lsArr[0] == '胜'){
								jc1 = '胜'
							}
							if(lsArr[0] == '平'){
								jc1 = '平'
							}
							if(lsArr[0] == '负'){
								jc1 = '负'
							}
						}
						
						//hg
						if(lsArr[1].indexOf('|')!==-1){
							let hgspArr = lsArr[1].split('|')
							if(hgspArr[0] == '胜' || hgspArr[0].indexOf('主')!==-1){
								hg1 = '胜'
							}
							if(hgspArr[0] == '平'){
								hg1 = '平'
							}
							if(hgspArr[0] == '负' || hgspArr[0].indexOf('客')!==-1){
								hg1 = '负'
							}
							if(hgspArr[1] == '胜' || hgspArr[1].indexOf('主')!==-1){
								hg2 = '胜'
							}
							if(hgspArr[1] == '平'){
								hg2 = '平'
							}
							if(hgspArr[1] == '负' || hgspArr[1].indexOf('客')!==-1){
								hg2 = '负'
							}
						}else{
							if(lsArr[1] == '胜' || lsArr[1].indexOf('主')!==-1){
								hg1 = '胜'
							}
							if(lsArr[1] == '平'){
								hg1 = '平'
							}
							if(lsArr[1] == '负'  || lsArr[1].indexOf('客')!==-1){
								hg1 = '负'
							}
						}
						
						
						
						item['jc'].forEach(item3=>{
							
								if(jc1 == '胜'){
									item3['胜样式'] = 'width: 120px;background:pink;'
									jchh1 = item['jc'][0]['胜胜'];
									jchh2 = item['jc'][0]['平胜'];
									jchh3 = item['jc'][0]['负胜'];
								}
								if(jc1 == '平'){
									item3['平样式'] = 'width: 120px;background:pink;'
									jcdd1 = item['jc'][0]['胜平'];
									jcdd2 = item['jc'][0]['平平'];
									jcdd3 = item['jc'][0]['负平'];
								}
								if(jc1 == '负'){
									item3['负样式'] = 'width: 120px;background:pink;'
									jchh1 = item['jc'][0]['胜负'];
									jchh2 = item['jc'][0]['平负'];
									jchh3 = item['jc'][0]['负负'];
								}
								if(jc2 == '胜'){
									item3['胜样式'] = 'width: 120px;background:pink;'
									jchh1 = item['jc'][0]['胜胜'];
									jchh2 = item['jc'][0]['平胜'];
									jchh3 = item['jc'][0]['负胜'];
								}
								if(jc2 == '平'){
									item3['平样式'] = 'width: 120px;background:pink;'
									jcdd1 = item['jc'][0]['胜平'];
									jcdd2 = item['jc'][0]['平平'];
									jcdd3 = item['jc'][0]['负平'];
								}
								if(jc2 == '负'){
									item3['负样式'] = 'width: 120px;background:pink;'
									jchh1 = item['jc'][0]['胜负'];
									jchh2 = item['jc'][0]['平负'];
									jchh3 = item['jc'][0]['负负'];
								}
							
						})
						if(item['名称'] !== '平&主|客-0.25' && item['名称'] !== '平&主|客-0.5' && item['名称'] !== '平&主|客0' && item['名称'] !== '平&主|客+0.25' ){
							item['hg'].forEach(item3=>{
								if(item3['让球'] == '胜平负' && hg1 == '平'){
									item3['平样式'] = 'width: 120px;background:pink;'
								}
								if(item3['让球'] == item['球数']){
									if(hg1 == '胜'){
										item3['胜样式'] = 'width: 120px;background:pink;'
									}
									// if(hg1 == '平'){
									// 	item3['平样式'] = 'width: 120px;background:pink;'
									// }
									if(hg1 == '负'){
										item3['负样式'] = 'width: 120px;background:pink;'
									}
									if(hg2 == '胜'){
										item3['胜样式'] = 'width: 120px;background:pink;'
									}
									// if(hg2 == '平'){
									// 	item3['平样式'] = 'width: 120px;background:pink;'
									// }
									if(hg2 == '负'){
										item3['负样式'] = 'width: 120px;background:pink;'
									}
								}
							})
						}else{
							item['hg'].forEach(item3=>{
								if(item3['让球'] == '胜平负' && hg1 == '胜'){
									item3['胜样式'] = 'width: 120px;background:pink;'
								}
								if(item3['让球'] == '胜平负' && hg1 == '负'){
									item3['负样式'] = 'width: 120px;background:pink;'
								}
								if(item3['让球'] == item['球数']){
									
									if(hg2 == '胜'){
										item3['胜样式'] = 'width: 120px;background:pink;'
									}
									if(hg2 == '负'){
										item3['负样式'] = 'width: 120px;background:pink;'
									}
								}
							})
						}
						
						if(item['名称'] == '胜|平&负' || item['名称'] == '负|平&胜' || item['名称'] == '胜|平&客-0.25' || item['名称'] == '胜|平&客-0.5' || item['名称'] == '胜|平&客0' || item['名称'] == '胜|平&客+0.25' || item['名称'] == '负|平&主-0.25' || item['名称'] == '负|平&主-0.5' || item['名称'] == '负|平&主0' || item['名称'] == '负|平&主+0.25'){
							jcpv1 = item['竞彩赔率'];
							
							jcpv2 = item['平赔率'];
							hgsf1 = item['皇冠赔率'];
							
						}
						if(item['名称'] == '胜&平|负' || item['名称'] == '负&平|胜' || item['名称'] == '胜&平|客-0.25' || item['名称'] == '胜&平|客0' || item['名称'] == '胜&平|客-0.5' || item['名称'] == '胜&平|客+0.25' || item['名称'] == '负&平|主-0.25' || item['名称'] == '负&平|主0' || item['名称'] == '负&平|主-0.5' || item['名称'] == '负&平|主+0.25'){
							jcpv1 = item['竞彩赔率'];
							hgp1 = item['平赔率'];
							hgsf1 = item['皇冠赔率'];
						}
						if(item['名称'] == '平&胜|负' || item['名称'] == '平&主|客-0.25' || item['名称'] == '平&主|客-0.5' || item['名称'] == '平&主|客0' || item['名称'] == '平&主|客+0.25' ){
							jcpv1 = item['竞彩平赔率'];
							hgp1 = item['皇冠主赔率'];
							hgsf1 = item['皇冠客赔率'];
						}
						
						if(item['名称'] == '胜&客+0.5' || item['名称'] == '负&主+0.5' || item['名称'] == '胜&客+0.5' || item['名称'] == '负&主+0.5'){
							jcpv1 = item['竞彩赔率'];
							hgsf1 = item['皇冠赔率'];
						}
						
						let plobj = {
							jcpv1:jcpv1,
							jcpv2:jcpv2,
							jchh1:jchh1,
							jchh2:jchh2,
							jchh3:jchh3,
							jcdd1:jcdd1,
							jcdd2:jcdd2,
							jcdd3:jcdd3,
							jchhje1:'',
							jchhje2:'',
							jchhje3:'',
							jcddje1:'',
							jcddje2:'',
							jcddje3:'',
							jcjine1:'10000',
							jcjine2:'',
							jcfd1:'',
							jcfd2:'',
							jczjm1:'',
							jczjm2:'',
							hgp1:hgp1,
							hgsf1:hgsf1,
							hgpjine1:'',
							hgsfjine1:'',
							hgfd1:'',
							hgfd2:'',
							hgzjm1:'',
							hgzjm2:'',
							mc1:mc1,
							f1:this.setArr[0].value,
							f2:this.setArr[1].value,
							lr:''
						}
						item['plobj'] = plobj
						this.tableData.push(JSON.parse(JSON.stringify(item)))
						
					})
				}
				this.tableData = this.tableData.filter((item)=>{
					return this.setArr[0].ssgl.indexOf(item.saishi.cc) ==-1;
				})
				if(this.setArr[0].guolv == 'today'){
					this.tableData = this.tableData.filter((item) => {
						//console.log(item)
					  return item.saishi.cc.indexOf(this.pdweek) !==-1;
					});
				}
				if(this.setArr[0].guolv == 'tm'){
					this.tableData = this.tableData.filter((item) => {
						//console.log(item)
					  return item.saishi.cc.indexOf(this.pdweek) !==-1 || item.saishi.cc.indexOf(this.pdTmweek) !==-1;
					});
				}
				this.tableData2 = this.tableData
				//console.log(this.tableData2,7737)
				this.$emit('bqc',this.tableData.length);
				//console.log(res.data,'全新单固页面')
			},
			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			handleClose(done) {
				done();
				// this.$confirm('确认关闭？').then(_ => {
				// 	done();
				// }).catch(_ => {});
			},
		},
		beforeMount() {
			var dt=new Date();
			this.pdweek = this.weekDay[dt.getDay()]
			this.getNewData()
		
		}
	}
</script>

<style scoped> 
.mc-count{
	width: 60px;
	
}
.fd{
	width: 100px;
	margin-left: 15px;
	/* padding-left: 15px; */
	
	/* text-align: center; */
}
.fd2{
	width: 100px;
	margin-left: 15px;
	margin-left: 115px;
	/* padding-left: 15px; */
	
	/* text-align: center; */
}
.hgfd{
	width: 115px;
	margin-left: 15px;
	
	/* text-align: center; */
}
.jc-bj {
		display: flex;
		justify-content: space-around;
	}
.counter-all {
		border-top: 2px solid #0DA969;
		display: flex;
		justify-content: space-between;
		width: 99%;
		font-size: 10px;

		padding-top: 10px;
	}

	.jc-region {
		width: 500px;
	}
	.hg-one-region{
		width: 350px;
	}
	.jc-bqc-box{
		display: flex;
		justify-content: space-around;
	}
	.bqc-s-all{
		width: 115px;
		line-height: 30px;
		/* background-color: pink; */
	}
	.bqc-p-all{
		width: 115px;
		line-height: 30px;
	}
	.bqc-f-all{
		width: 115px;
		line-height: 30px;
	}
	.dialog-footer{
		display: flex;
		justify-content: flex-end;
	}
</style>