<template>
	<div>
		<el-dialog
		title="投注计算器" 
		v-dialogDrag
		:modal="false"
		:visible.sync="dialogVisible"
		 width="40%" 
		 :before-close="handleClose">
			<!-- 计算界面 -->
			<div class="counter-all">
				<div class="jc-region">
					<div style="text-align: center;display: flex; height: 50px; line-height: 50px;justify-content: space-around;">
						<div>彩票</div>
						
						<el-select v-model="value" size="small" style="width: 160px;" placeholder="请选择">
						    <el-option
						      v-for="item in options"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value">
						    </el-option>
						  </el-select>
						</div>
					<div style="display: flex; height: 40px;line-height: 40px;margin-top: 15px;">
						<div class="mc-count">赔率</div>
						<el-input style="width: 115px;" size="small" v-model="countObj.jcpv1"></el-input>
						<el-input style="width: 115px;" disabled size="small" v-model="countObj.jcpv2"></el-input>
					</div>
					<div style="height: 30px;line-height: 30px; display: flex;">
						<div class="mc-count">投注</div>
						<el-input style="width: 115px;" size="small" v-model="countObj.jcjine1"></el-input>
						<el-input style="width: 115px;" size="small" disabled v-model="countObj.jcjine2"></el-input>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="mc-count">返点</div>
						<div class="fd">{{countObj.jcfd1}}</div>
						<div class="fd">{{countObj.jcfd2}}</div>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="mc-count">中奖金额</div>
						<div class="fd">{{countObj.jczjm1}}</div>
						<div class="fd">{{countObj.jczjm2}}</div>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="mc-count">利润</div>
						<div class="fd" >{{countObj.lr}}</div>
						<div class="fd" v-show="countObj.jcfd2 !=0">{{countObj.lr}}</div>
						<div class="fd" v-show="countObj.jcfd2 ==0"></div>
					</div>
				</div>
				<div class="hg-one-region">
					<div style="text-align: center;display: flex; height: 50px; line-height: 50px;justify-content: space-around;">
						<div>外场</div>
						<el-select v-model="value2" size="small" style="width: 160px;" placeholder="请选择">
						    <el-option
						      v-for="item in options2"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value">
						    </el-option>
						  </el-select>
					</div>
					<div style="margin-top: 19px;">
						<el-input style="width: 130px;" size="small" disabled v-model = "countObj.hgp1"></el-input><el-input
							style="width: 130px;" size="small" v-model="countObj.hgsf1"></el-input>
					</div>
					<div style="margin-top: 4px;">
						<el-input style="width: 130px;" size="small" disabled v-model="countObj.hgpjine1"></el-input><el-input
							style="width: 130px;" size="small" disabled v-model="countObj.hgsfjine1"></el-input>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="hgfd">{{countObj.hgfd1}}</div>
						<div class="hgfd">{{countObj.hgfd2}}</div>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="hgfd">{{countObj.hgzjm1}}</div>
						<div class="hgfd">{{countObj.hgzjm2}}</div>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="hgfd" v-show="countObj.hgfd1 != 0">{{countObj.lr}}</div>
						<div class="hgfd" v-show="countObj.hgfd1 == 0"></div>
						<div class="hgfd" v-show="countObj.hgfd2 !=0">{{countObj.lr}}</div>
					</div>
				</div>
				
			</div>
		
		
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">关闭</el-button>
				<el-button type="success" @click="formulaSub">计算</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props:{
			setArr:{
				type:Array,
				required: false,
			}
		},
		data(){
			return{
				options: [{
				          value: '胜',
				          label: '胜'
				        }, {
				          value: '负',
				          label: '负'
				        }],
				options2: [{
						          value: '胜&客+0.5',
						          label: '负'
						        }],
				value: '胜',
				value2:'胜&客+0.5',
				oneHgping:false,
				countObj2:{},
				countObj:{
					jcpv1:0,
					jcpv2:0,
					jcjine1:'1000',
					jcjine2:0,
					jcfd1:0,
					jcfd2:0,
					jczjm1:0,
					jczjm2:0,
					hgp1:0,
					hgsf1:0,
					hgpjine1:0,
					hgsfjine1:0,
					hgfd1:0,
					hgfd2:0,
					hgzjm1:0,
					hgzjm2:0,
					mc1:0,
					f1:0,
					f2:0,
					lr:0
				},
				dialogVisible:false,
				tableData:[],
				resultArr:[],
				ssArr:[],
				bg2:'width: 120px;'
			}
		},
		watch:{
			value(newvalue, old){
				if(newvalue == '胜'){
					this.options2 = [
						{
						          value: '胜&客+0.5',
						          label: '负'
						        }
					]
					this.value2 = '胜&客+0.5'
				}
				
				if(newvalue == '负'){
					this.options2 = [
						{
						          value: '胜&客+0.5',
						          label: '胜'
						        }
					];
					this.value2 = '胜&客+0.5'
				}
				
			}
		},
		methods:{
			formula(item){
				
				this.countObj={
					jcpv1:0,
					jcpv2:0,
					jcjine1:'1000',
					jcjine2:0,
					jcfd1:0,
					jcfd2:0,
					jczjm1:0,
					jczjm2:0,
					hgp1:0,
					hgsf1:0,
					hgpjine1:0,
					hgsfjine1:0,
					hgfd1:0,
					hgfd2:0,
					hgzjm1:0,
					hgzjm2:0,
					mc1:0,
					f1:this.setArr[0].value,
					f2:this.setArr[1].value,
					lr:0
				}
				this.dialogVisible = true;
				//this.countObj2 = JSON.parse(JSON.stringify(item.plobj));
				// this.countObj = item.plobj;
				//console.log(item,'计算按钮点击了')
			},
			async formulaSub(){
				
				this.countObj.mc1 = this.value2
					
				const res = await this.$http({
					path: '/dg/formula/new',
					method: 'post',
					params: this.countObj
				});
				if(res.data.message == '无效的token'){
					return this.$message.error('登录失效，请重新登录')
				}
				if(res.data.code == 200 && res.data.result){
					let ceshi = this.ffym(res.data.result,this.cl)
					let result = JSON.parse(ceshi)
					this.countObj = result
				}
				//console.log(this.countObj,222)
			},
			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			handleClose(done) {
				done();
				// this.$confirm('确认关闭？').then(_ => {
				// 	done();
				// }).catch(_ => {});
			},
		}
	}
</script>

<style scoped> 
.mc-count{
	width: 60px;
	
}
.fd{
	width: 100px;
	margin-left: 15px;
	/* padding-left: 15px; */
	
	/* text-align: center; */
}
.hgfd{
	width: 115px;
	margin-left: 15px;
	
	/* text-align: center; */
}
.jc-bj {
		display: flex;
		justify-content: space-around;
	}
.counter-all {
		border-top: 2px solid #0DA969;
		display: flex;
		justify-content: space-between;
		width: 99%;
		font-size: 13px;

		padding-top: 10px;
	}

	.jc-region {
		width: 350px;
	
	}
	.hg-one-region{
		width: 350px;
	}
</style>