import { render, staticRenderFns } from "./bqcFormula.vue?vue&type=template&id=9892f554&scoped=true&"
import script from "./bqcFormula.vue?vue&type=script&lang=js&"
export * from "./bqcFormula.vue?vue&type=script&lang=js&"
import style0 from "./bqcFormula.vue?vue&type=style&index=0&id=9892f554&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9892f554",
  null
  
)

export default component.exports