<template>
	<div >
		<div style="margin:20px;line-height: 30px;">方案数量:
		<span style="color: orangered;">{{tableData2.length}}</span>
		</div>
		<el-table ref="multipleTable" border :header-cell-style="{textAlign: 'center',  }" stripe
			:data="tableData2" tooltip-effect="dark" style="width: 99%" 
			@selection-change="handleSelectionChange">
			<el-table-column align="center" type="selection" width="55">
			</el-table-column>
			<el-table-column align="center" prop="saishi" label="赛事(时间)" show-overflow-tooltip>
				<template slot-scope="scope">
					<div >{{scope.row.saishi.cc}}</div>
					<div >{{scope.row.saishi.date}}</div>
					<div >{{scope.row.saishi.jcName}}</div>
					<div >{{scope.row.saishi.hgName}}</div>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="jc" label="彩票(总进球数)" width="720">
				<template slot-scope="scope">
					<div class="jc-bj" >
						<div v-for="item in scope.row.jc">
							<div class="jc-jqs" >
								{{item['球数']}}
							</div>
							<div class="jc-jqs">{{item['赔率']}}</div>
						</div>
					</div>
				
				</template>
			</el-table-column>
			<el-table-column align="center" prop="hg" label="皇冠(全场大小)" width="180">
				<template slot-scope="scope">
					<div class="hg-bj">
						<div class="hg-bmq" style="background-color: hotpink;" >
							<div >让</div>
							<div >{{scope.row.hg['球数']}}</div>
						</div>
						<div class="hg-bmq" >
							<div>大</div>
							<div>{{scope.row.hg['odds1']}}</div>
						</div>
						<div class="hg-bmq" >
							<div>小</div>
							<div >{{scope.row.hg['odds2']}}</div>
						</div>
					</div>
				
				</template>
			</el-table-column>
			<el-table-column align="center" prop="lr" label="利润率" width="80">
				<template slot-scope="scope">
					<div class="" >{{(scope.row.profit*100).toFixed(2)}}% </div>
					<div class="" >{{scope.row['方案']}} </div>
					<el-button size="mini" type="success" @click="formula(scope.row)">计算</el-button>	
					
					
				</template>
			</el-table-column>
		</el-table>
		<!-- 计算器弹窗 -->
		<el-dialog 
		title="大球计算器" 
		v-dialogDrag
		:modal="false"
		custom-class="schedule_task_diaog"
		:visible.sync="dialogVisible" 
		width="60%" 
		
		:before-close="handleClose">
			<!-- 计算界面 -->
			<div class="jisuanqi">
				<div class="count-bj">
					<div class="mc-count">名称</div>
					<div class="qs-bj-count" v-for="(item,i) in countObj" :key="item.id">
						{{item.qs}}
					</div>
				</div>
				<div class="count-bj">
					<div class="mc-count">赔率</div>
					<div class="ps-bj-count" v-for="(item,i) in countObj" :key="item.id">
						<el-input size="small" v-model="item.pl"></el-input>
					</div>
				</div>	
				<div class="count-bj">
					<div class="mc-count">投注</div>
					<div class="ps-bj-count" v-for="(item,i) in countObj" :key="item.id">
						<el-input size="small" :disabled="item.id !== 0" v-model="item.tz"></el-input>
					</div>
				</div>
				<div class="count-bj">
					<div class="mc-count">返点</div>
					<div class="ms-bj-count" v-for="(item,i) in countObj" :key="item.id">
						{{item.fd}}
					</div>
				</div>
				<div class="count-bj">
					<div class="mc-count">中奖金额</div>
					<div class="ms-bj-count" v-for="(item,i) in countObj" :key="item.id">
						{{item.zj}}
					</div>
				</div>
				<div class="count-bj">
					<div class="mc-count">利润</div>
					<div class="ms-bj-count" v-for="(item,i) in countObj" :key="item.id">
						{{item.lr}}
					</div>
				</div>
						
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">关闭</el-button>
				<el-button type="success" @click="formulaSub">计算</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props:{
			setArr:{
				type:Array,
				required: false,
			}
		},
		data(){
			return{
				weekDay:["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
				pdweek:'',
				pdTmweek:'',
				value:'',
				qsArr:['0','1','2','3','4','5','6','7+','让','大'],
				plArr:[],
				dialogVisible:false,
				countObj:[],
				tableData:[],
				tableData2:[]
			}
		},
		methods:{
			formula(item){
				this.dialogVisible = true;
				
				this.countObj = [
					{id:0,
					qs:'0',
					pl:item.jc[0]['赔率'],
					tz:1000,
					fd:0,
					zj:0,
					lr:0
					},
					{id:1,
					qs:'1',
					pl:item.jc[1]['赔率'],
					tz:0,
					fd:0,
					zj:0,
					lr:0
					},
					{id:2,
					qs:'2',
					pl:item.jc[2]['赔率'],
					tz:0,
					fd:0,
					zj:0,
					lr:0
					},
					{id:3,
					qs:'3',
					pl:item.jc[3]['赔率'],
					tz:0,
					fd:0,
					zj:0,
					lr:0
					},
					{id:4,
					qs:'4',
					pl:item.jc[4]['赔率'],
					tz:0,
					fd:0,
					zj:0,
					lr:0
					},
					{id:5,
					qs:'5',
					pl:item.jc[5]['赔率'],
					tz:0,
					fd:0,
					zj:0,
					lr:0
					},
					{id:6,
					qs:'6',
					pl:item.jc[6]['赔率'],
					tz:0,
					fd:0,
					zj:0,
					lr:0
					},
					{id:7,
					qs:'7+',
					pl:item.jc[7]['赔率'],
					tz:0,
					fd:0,
					zj:0,
					lr:0
					},
					{id:8,
					qs:'让',
					pl:item.hg['球数'],
					tz:0,
					fd:0,
					zj:0,
					lr:0
					},
					{id:9,
					qs:'大',
					pl:item.hg['odds1'],
					tz:0,
					fd:0,
					zj:0,
					lr:0
					}
				];
				//console.log(this.countObj)
				// item.jc.forEach(item2=>{
					
				// })
				//this.countObj = item;
				//console.log(item,'111')
			},
			async formulaSub(){
				const res = await this.$http({
					path: '/bm/formula',
					method: 'post',
					params: {
						f1:this.setArr[0].value,
						f2:this.setArr[1].value,
						countObj:this.countObj
					}
				});
				//console.log(res.data)
				if(res.data.message == '无效的token'){
					return this.$message.error('登录失效，请重新登录')
				}
				if(res.data.code == 200){
					let ceshi = this.ffym(res.data.result,this.cl)
					let result = JSON.parse(ceshi)
					this.countObj = result
				}
			},
			async getbmData(){
				var dt=new Date();
				let dtTm = dt.getDay()+1;
				if(dtTm == 7){
					dtTm = 0;
				}
				this.pdTmweek = this.weekDay[dtTm]
				this.pdweek = this.weekDay[dt.getDay()]
				//this.tableData = [];
				const res = await this.$http({
					path: '/bm/count',
					method: 'post',
					params: {
						"cf": this.setArr[0].value,
						"wf": this.setArr[1].value,
						"money": 100,
						"gl": this.setArr[2].value
					}
				});
				//console.log(res.data,'大小请求数据')
				if(res.data.message == '无效的token'){
					return this.$message.error('登录失效，请重新登录')
				}
				if(res.data.code == 200){
					let ceshi = this.ffym(res.data.result,this.cl)
					let result = JSON.parse(ceshi)
					this.tableData = result;
					//console.log(this.tableData,11212)
					for(let i = 0;i<this.tableData.length;i++){
						this.tableData[i]['方案'] = '方案'+(i+1)
					}
				}
				this.tableData = this.tableData.filter((item)=>{
					return this.setArr[0].ssgl.indexOf(item.saishi.cc) ==-1;
				})
				if(this.setArr[0].guolv == 'today'){
					this.tableData = this.tableData.filter((item) => {
						//console.log(item)
					  return item.saishi.cc.indexOf(this.pdweek) !==-1;
					});
				}
				if(this.setArr[0].guolv == 'tm'){
					this.tableData = this.tableData.filter((item) => {
						//console.log(item)
					  return item.saishi.cc.indexOf(this.pdweek) !==-1 || item.saishi.cc.indexOf(this.pdTmweek) !==-1;
					});
				}
				this.tableData2 = this.tableData
				this.$emit('bm',this.tableData.length);
				//console.log(res.data,'大小球')
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			handleClose(done){
				done()
			}
		},
		mounted() {
			var dt=new Date();
			let dtTm = dt.getDay()+1;
			if(dtTm == 7){
				dtTm = 0;
			}
			this.pdTmweek = this.weekDay[dtTm]
			this.pdweek = this.weekDay[dt.getDay()]
			this.getbmData()
		
		}
	}
</script>

<style scoped>
	.jisuanqi{
	
		width: 880px;
	}
	.ms-bj-count{
		width: 79px;
		text-align: center;
		overflow: hidden;
	}
	.ps-bj-count{
		width: 79px;
		
		text-align: center;
		overflow: hidden;
	}
	.qs-bj-count{
		width: 80px;
		text-align: center;
		overflow: hidden;
	}
	.mc-count{
		width: 60px;
		
	}
	.count-bj{
		
		margin: 10px 0;
		height: 40px;
		line-height: 40px;
		font-size: 13px;
		text-align: center;
		display: flex;
		justify-content: space-between;
	}
	.jc-bj{
		display: flex;
		justify-content: space-between;
	}
	.jc-jqs{
		width: 85px;
		margin: 3px 0;
		
	}
	.hg-bj{
		display: flex;
		justify-content: space-between;
		text-align: center;
	}
	.hg-bmq{
		width: 55px;
		margin: 0 1px;
	}
</style>