<template>
	<div>
		<el-dialog
		title="小球计算器" 
		v-dialogDrag
		:modal="false"
		custom-class="schedule_task_diaog"
		:visible.sync="dialogVisible" 
		width="60%" 
		
		:before-close="handleClose">
			<!-- 计算界面 -->
			<div class="jisuanqi">
				<div class="count-bj">
					<div class="mc-count">名称</div>
					<div class="qs-bj-count" v-for="(item,i) in countObj" :key="item.id">
						{{item.qs}}
					</div>
				</div>
				<div class="count-bj">
					<div class="mc-count">赔率</div>
					<div class="ps-bj-count" v-for="(item,i) in countObj" :key="item.id">
						<el-input size="small" v-model="item.pl"></el-input>
					</div>
				</div>	
				<div class="count-bj">
					<div class="mc-count">投注</div>
					<div class="ps-bj-count" v-for="(item,i) in countObj" :key="item.id">
						<el-input size="small" :disabled="item.id !== 7" v-model="item.tz"></el-input>
					</div>
				</div>
				<div class="count-bj">
					<div class="mc-count">返点</div>
					<div class="ms-bj-count" v-for="(item,i) in countObj" :key="item.id">
						{{item.fd}}
					</div>
				</div>
				<div class="count-bj">
					<div class="mc-count">中奖金额</div>
					<div class="ms-bj-count" v-for="(item,i) in countObj" :key="item.id">
						{{item.zj}}
					</div>
				</div>
				<div class="count-bj">
					<div class="mc-count">利润</div>
					<div class="ms-bj-count" v-for="(item,i) in countObj" :key="item.id">
						{{item.lr}}
					</div>
				</div>
						
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">关闭</el-button>
				<el-button type="success" @click="formulaSub">计算</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props:{
			setArr:{
				type:Array,
				required: false,
			},
			
		},
		data(){
			return{
				value:'',
				qsArr:['0','1','2','3','4','5','6','7+','让','小'],
				plArr:[],
				dialogVisible:false,
				countObj:[
					{id:0,
					qs:'0',
					pl:0,
					tz:0,
					fd:0,
					zj:0,
					lr:0
					},
					{id:1,
					qs:'1',
					pl:0,
					tz:0,
					fd:0,
					zj:0,
					lr:0
					},
					{id:2,
					qs:'2',
					pl:0,
					tz:0,
					fd:0,
					zj:0,
					lr:0
					},
					{id:3,
					qs:'3',
					pl:0,
					tz:0,
					fd:0,
					zj:0,
					lr:0
					},
					{id:4,
					qs:'4',
					pl:0,
					tz:0,
					fd:0,
					zj:0,
					lr:0
					},
					{id:5,
					qs:'5',
					pl:0,
					tz:0,
					fd:0,
					zj:0,
					lr:0
					},
					{id:6,
					qs:'6',
					pl:0,
					tz:0,
					fd:0,
					zj:0,
					lr:0
					},
					{id:7,
					qs:'7+',
					pl:0,
					tz:0,
					fd:0,
					zj:0,
					lr:0
					},
					{id:8,
					qs:'让',
					pl:2,
					tz:0,
					fd:0,
					zj:0,
					lr:0
					},
					{id:9,
					qs:'小',
					pl:0,
					tz:0,
					fd:0,
					zj:0,
					lr:0
					}
				],
				tableData:[]
			}
		},
		methods:{
			formula(item){
				this.countObj = [
						{id:0,
						qs:'0',
						pl:0,
						tz:0,
						fd:0,
						zj:0,
						lr:0
						},
						{id:1,
						qs:'1',
						pl:0,
						tz:0,
						fd:0,
						zj:0,
						lr:0
						},
						{id:2,
						qs:'2',
						pl:0,
						tz:0,
						fd:0,
						zj:0,
						lr:0
						},
						{id:3,
						qs:'3',
						pl:0,
						tz:0,
						fd:0,
						zj:0,
						lr:0
						},
						{id:4,
						qs:'4',
						pl:0,
						tz:0,
						fd:0,
						zj:0,
						lr:0
						},
						{id:5,
						qs:'5',
						pl:0,
						tz:0,
						fd:0,
						zj:0,
						lr:0
						},
						{id:6,
						qs:'6',
						pl:0,
						tz:0,
						fd:0,
						zj:0,
						lr:0
						},
						{id:7,
						qs:'7+',
						pl:0,
						tz:0,
						fd:0,
						zj:0,
						lr:0
						},
						{id:8,
						qs:'让',
						pl:2,
						tz:0,
						fd:0,
						zj:0,
						lr:0
						},
						{id:9,
						qs:'小',
						pl:0,
						tz:0,
						fd:0,
						zj:0,
						lr:0
						}
					]
				this.dialogVisible = true;
			},
			async formulaSub(){
				const res = await this.$http({
					path: '/small/formula',
					method: 'post',
					params: {
						f1:this.setArr[0].value,
						f2:this.setArr[1].value,
						countObj:this.countObj
					}
				});
				if(res.data.message == '无效的token'){
					return this.$message.error('登录失效，请重新登录')
				}
				//console.log(res.data)
				if(res.data.code == 200){
					let ceshi = this.ffym(res.data.result,this.cl)
					let result = JSON.parse(ceshi)
					this.countObj = result
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			handleClose(done){
				done()
			}
		},
		beforeMount() {
			
		}
	}
</script>

<style scoped>
	.jisuanqi{
	
		width: 880px;
	}
	.ms-bj-count{
		width: 79px;
		text-align: center;
		overflow: hidden;
	}
	.ps-bj-count{
		width: 79px;
		
		text-align: center;
		overflow: hidden;
	}
	.qs-bj-count{
		width: 80px;
		text-align: center;
		overflow: hidden;
	}
	.mc-count{
		width: 60px;
		
	}
	.count-bj{
		
		margin: 10px 0;
		height: 40px;
		line-height: 40px;
		font-size: 13px;
		text-align: center;
		display: flex;
		justify-content: space-between;
	}
	.jc-bj{
		display: flex;
		justify-content: space-between;
	}
	.jc-jqs{
		width: 85px;
		margin: 3px 0;
		
	}
	.hg-bj{
		display: flex;
		justify-content: space-between;
		text-align: center;
	}
	.hg-bmq{
		width: 55px;
		margin: 0 1px;
	}
</style>