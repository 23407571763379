<template>
	<div>
		<el-dialog
		title="投注计算器" 
		v-dialogDrag
		:modal="false"
		:visible.sync="dialogVisible"
		 width="60%" 
		 :before-close="handleClose">
			<!-- 计算界面 -->
			<div class="counter-all">
				<div class="jc-region">
					<div style="text-align: center;display: flex; height: 50px; line-height: 50px;justify-content: space-around;">
						<div>竞彩</div>
						
						<el-select v-model="value" size="small" style="width: 160px;" placeholder="请选择">
						    <el-option
						      v-for="item in options"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value">
						    </el-option>
						  </el-select>
						</div>
					
					<div style="display: flex; height: 40px;line-height: 40px;margin-top: 15px;">
						<div class="mc-count">赔率</div>
						<el-input style="width: 70px;" size="small" v-model="countObj.jchh1"></el-input>
						<el-input style="width: 70px;" size="small" v-model="countObj.jchh2"></el-input>
						<el-input style="width: 70px;" size="small" v-model="countObj.jchh3"></el-input>
						<el-input style="width: 70px;" size="small" v-model="countObj.jcdd1"></el-input>
						<el-input style="width: 70px;" size="small" v-model="countObj.jcdd2"></el-input>
						<el-input style="width: 70px;" size="small" v-model="countObj.jcdd3"></el-input>
					</div>
					<div style="height: 30px;line-height: 30px;display: flex;">
						<div class="mc-count">投注</div>
						<el-input style="width: 70px;" size="small" disabled v-model="countObj.jchhje1"></el-input>
						<el-input style="width: 70px;" size="small" disabled v-model="countObj.jchhje2"></el-input>
						<el-input style="width: 70px;" size="small" disabled v-model="countObj.jchhje3"></el-input>
						<el-input style="width: 70px;" size="small" disabled v-model="countObj.jcddje1"></el-input>
						<el-input style="width: 70px;" size="small" disabled v-model="countObj.jcddje2"></el-input>
						<el-input style="width: 70px;" size="small" disabled v-model="countObj.jcddje3"></el-input>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="mc-count">返点</div>
						<div class="fd">{{countObj.jcfd1}}</div>
						<div class="fd2">{{countObj.jcfd2}}</div>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="mc-count">奖金</div>
						<div class="fd">{{countObj.jczjm1}}</div>
						<div class="fd2">{{countObj.jczjm2}}</div>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="mc-count">利润</div>
						<div class="fd" >{{countObj.lr}}</div>
						<div class="fd2" v-show="countObj.jcfd2 !==''">{{countObj.lr}}</div>
						<div class="fd2" v-show="countObj.jcfd2 ===''"></div>
					</div>
				</div>
				<div class="hg-one-region">
					<div style="text-align: center;display: flex; height: 50px; line-height: 50px;justify-content: space-around;">
						<div>外场</div>
						<el-select v-model="value2" size="small" style="width: 160px;" placeholder="请选择">
						    <el-option
						      v-for="item in options2"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value">
						    </el-option>
						  </el-select>
					</div>
					<div style="margin-top: 19px;">
						<el-input style="width: 130px;" size="small" :disabled="oneHgping" v-model = "countObj.hgp1"></el-input><el-input
							style="width: 130px;" size="small" v-model="countObj.hgsf1"></el-input>
					</div>
					<div style="margin-top: 4px;">
						<el-input style="width: 130px;" size="small" disabled v-model="countObj.hgpjine1"></el-input><el-input
							style="width: 130px;" size="small" disabled v-model="countObj.hgsfjine1"></el-input>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="hgfd">{{countObj.hgfd1}}</div>
						<div class="hgfd">{{countObj.hgfd2}}</div>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="hgfd">{{countObj.hgzjm1}}</div>
						<div class="hgfd">{{countObj.hgzjm2}}</div>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div class="hgfd" v-show="countObj.hgfd1 != 0">{{countObj.lr}}</div>
						<div class="hgfd" v-show="countObj.hgfd1 == 0"></div>
						<div class="hgfd" v-show="countObj.hgfd2 !=0">{{countObj.lr}}</div>
					</div>
				</div>
				
			</div>
		
		
			<span slot="footer" class="dialog-footer">
				<div style="line-height: 40px;margin-right: 30px;">
					投注金额(竞彩)
					<el-input  style="width: 100px; line-height: 40px;text-align: center;" size="small" v-model = "countObj.jcjine1"></el-input>
				</div>
				<el-button @click="dialogVisible = false">关闭</el-button>
				<el-button type="success" @click="formulaSub">计算</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props:{
			setArr:{
				type:Array,
				required: false,
			}
		},
		data(){
			return{
				options: [{
				          value: '胜',
				          label: '胜胜-平胜-负胜'
				        }, {
				          value: '平',
				          label: '胜平-平平-负平'
				        }, {
				          value: '胜|平',
				          label: '*胜|*平'
				        }],
				options2: [{
						          value: '胜&客+0.5',
						          label: '客+0.5'
						        }, {
						          value: '胜&平|负',
						          label: '平|负'
						        }, {
						          value: '胜&平|客+0.25',
						          label: '平|客+0.25'
						        }, {
						          value: '胜&平|客0',
						          label: '平|客0'
						        }, {
						          value: '胜&平|客-0.25',
						          label: '平|客-0.25'
						        }, {
						          value: '胜&平|客-0.5',
						          label: '平|客-0.5'
						        }],
				value: '胜',
				value2:'胜&客+0.5',
				oneHgping:false,
				countObj2:{},
				// countObj:{
				// 	jcpv1:0,
				// 	jcpv2:0,
				// 	jcjine1:'10000',
				// 	jcjine2:0,
				// 	jcfd1:0,
				// 	jcfd2:0,
				// 	jczjm1:0,
				// 	jczjm2:0,
				// 	hgp1:0,
				// 	hgsf1:0,
				// 	hgpjine1:0,
				// 	hgsfjine1:0,
				// 	hgfd1:0,
				// 	hgfd2:0,
				// 	hgzjm1:0,
				// 	hgzjm2:0,
				// 	mc1:0,
				// 	f1:0,
				// 	f2:0,
				// 	lr:0
				// },
				countObj:{
					jcpv1:0,
					jcpv2:0,
					jchh1:0,
					jchh2:0,
					jchh3:0,
					jcdd1:0,
					jcdd2:0,
					jcdd3:0,
					jchhje1:'',
					jchhje2:'',
					jchhje3:'',
					jcddje1:'',
					jcddje2:'',
					jcddje3:'',
					jcjine1:'10000',
					jcjine2:'',
					jcfd1:'',
					jcfd2:'',
					jczjm1:'',
					jczjm2:'',
					hgp1:0,
					hgsf1:0,
					hgpjine1:'',
					hgsfjine1:'',
					hgfd1:'',
					hgfd2:'',
					hgzjm1:'',
					hgzjm2:'',
					mc1:0,
					f1:0,
					f2:0,
					lr:''
				},
				dialogVisible:false,
				tableData:[],
				resultArr:[],
				ssArr:[],
				bg2:'width: 120px;'
			}
		},
		watch:{
			value(newvalue, old){
				if(newvalue == '胜'){
					this.options2 = [
						{
						          value: '胜&客+0.5',
						          label: '客+0.5'
						        }, {
						          value: '胜&平|负',
						          label: '平|负'
						        }, {
						          value: '胜&平|客+0.25',
						          label: '平|客+0.25'
						        }, {
						          value: '胜&平|客0',
						          label: '平|客0'
						        }, {
						          value: '胜&平|客-0.25',
						          label: '平|客-0.25'
						        }, {
						          value: '胜&平|客-0.5',
						          label: '平|客-0.5'
						        }
					]
					this.value2 = '胜&客+0.5'
				}
				if(newvalue == '平'){
					this.options2 = [
						{
						          value: '平&胜|负',
						          label: '胜|负'
						        }, {
						          value: '平&胜|客+0.25',
						          label: '胜|客+0.25'
						        }, {
						          value: '平&胜|客0',
						          label: '胜|客0'
						        }, {
						          value: '平&胜|客-0.25',
						          label: '胜|客-0.25'
						        }, {
						          value: '平&胜|客-0.5',
						          label: '胜|客-0.5'
						        }
					];
					this.value2 = '平&胜|负'
				}
				if(newvalue == '胜|平'){
					this.options2 = [
						{
						          value: '胜|平&负',
						          label: '负'
						        }, {
						          value: '胜|平&客+0.25',
						          label: '客+0.25'
						        }, {
						          value: '胜|平&客0',
						          label: '客0'
						        }, {
						          value: '胜|平&客-0.25',
						          label: '客-0.25'
						        }, {
						          value: '胜|平&客-0.5',
						          label: '客-0.5'
						        }
					];
					this.value2 = '胜|平&负'
				}
				
			}
		},
		methods:{
			formula(item){
				
				this.countObj={
					jcpv1:0,
					jcpv2:0,
					jchh1:0,
					jchh2:0,
					jchh3:0,
					jcdd1:0,
					jcdd2:0,
					jcdd3:0,
					jchhje1:'',
					jchhje2:'',
					jchhje3:'',
					jcddje1:'',
					jcddje2:'',
					jcddje3:'',
					jcjine1:'10000',
					jcjine2:'',
					jcfd1:'',
					jcfd2:'',
					jczjm1:'',
					jczjm2:'',
					hgp1:0,
					hgsf1:0,
					hgpjine1:'',
					hgsfjine1:'',
					hgfd1:'',
					hgfd2:'',
					hgzjm1:'',
					hgzjm2:'',
					mc1:0,
					f1:this.setArr[0].value,
					f2:this.setArr[1].value,
					lr:''
				}
				this.dialogVisible = true;
				//this.countObj2 = JSON.parse(JSON.stringify(item.plobj));
				// this.countObj = item.plobj;
				//console.log(item,'计算按钮点击了')
			},
			calBqc(a,b,c){
				return Number((1/(1/a+1/b+1/c)).toFixed(2))
			},
			youhua(a,b,c){
				let zba = (1/a)/((1/a)+(1/b)+(1/c))
				let zbb = (1/b)/((1/a)+(1/b)+(1/c))
				let zbc = (1/c)/((1/a)+(1/b)+(1/c))
				let zbObj = {
					bla:zba,
					blb:zbb,
					blc:zbc
				}
				return zbObj
			},
			async formulaSub(){
				this.countObj.jcpv1 = this.calBqc(this.countObj.jchh1,this.countObj.jchh2,this.countObj.jchh3)
				if(this.countObj.jcdd1 != ''){
					this.countObj.jcpv2 = this.calBqc(this.countObj.jcdd1,this.countObj.jcdd2,this.countObj.jcdd3)
				}
				this.countObj.mc1 = this.value2
					
				const res = await this.$http({
					path: '/dg/formula/new',
					method: 'post',
					params: this.countObj
				});
				if(res.data.message == '无效的token'){
					return this.$message.error('登录失效，请重新登录')
				}
				// if(res.data.code == 200 && res.data.result){
					
				// 	this.countObj = res.data.result
				// }
				//console.log(this.countObj,222)
				let fhData = null;
				if(res.data.code == 200){
					let ceshi = this.ffym(res.data.result,this.cl)
					let result = JSON.parse(ceshi)
					fhData = result;
					let zbObj1 = this.youhua(Number(fhData.jchh1),Number(fhData.jchh2),Number(fhData.jchh3))
					fhData.jchhje1 = (fhData.jcjine1*zbObj1.bla).toFixed(2)
					fhData.jchhje2 = (fhData.jcjine1*zbObj1.blb).toFixed(2)
					fhData.jchhje3 = (fhData.jcjine1*zbObj1.blc).toFixed(2)
					if(fhData.jcjine2 !=""){
						let zbObj2 = this.youhua(Number(fhData.jcdd1),Number(fhData.jcdd2),Number(fhData.jcdd3))
						fhData.jcddje1 = (fhData.jcjine2*zbObj2.bla).toFixed(2)
						fhData.jcddje2 = (fhData.jcjine2*zbObj2.blb).toFixed(2)
						fhData.jcddje3 = (fhData.jcjine2*zbObj2.blc).toFixed(2)
					}
					this.countObj = fhData
					//console.log(fhData,1212)
					//this.countObj = res.data.result
				}
			},
			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			handleClose(done) {
				done();
				// this.$confirm('确认关闭？').then(_ => {
				// 	done();
				// }).catch(_ => {});
			},
		}
	}
</script>

<style scoped> 
.mc-count{
	width: 60px;
	
}
.fd{
	width: 100px;
	margin-left: 15px;
	/* padding-left: 15px; */
	
	/* text-align: center; */
}
.hgfd{
	width: 115px;
	margin-left: 15px;
	
	/* text-align: center; */
}
.jc-bj {
		display: flex;
		justify-content: space-around;
	}
.counter-all {
		border-top: 2px solid #0DA969;
		display: flex;
		justify-content: space-between;
		width: 99%;
		font-size: 13px;

		padding-top: 10px;
	}

	.jc-region {
		width: 500px;
	
	}
	.hg-one-region{
		width: 350px;
	}
	.dialog-footer{
		display: flex;
		justify-content: flex-end;
	}
</style>