<template>
	<div>
		<el-dialog title="投注计算器"
		:visible.sync="dialogVisible" 
		v-dialogDrag
		:modal="false"
		
		width="55%" 
		:before-close="handleClose">
			<!-- 计算界面 -->
			<div class="counter-all">
				<div class="jc-region">
					<div style="text-align: center;height: 50px;line-height: 50px;">彩票</div>
					<div class="count-li1">
						<div style="width: 50px;text-align: center;">赔率 </div>
						<el-input class="count-li2" size="small" v-model="countObj.jcpv1"></el-input> X <el-input
							class="count-li2" size="small" v-model="countObj.jcpv2"></el-input>
					</div>
					<div class="count-li3">
						<div style="width: 50px;text-align: center;">投注</div>
						<el-input class="count-li4" size="mini" v-model="countObj.jcjine"></el-input>
					</div>
					<div class="count-li5">
						<div style="width: 50px;text-align: center;">返点</div> 
						<div style="width: 180px;text-align: center;">{{countObj.jcfd}}</div>
					</div>
					<div style="height: 30px;line-height: 30px;display: flex;">
						<div style="width: 50px;text-align: center;">中将金额</div>
						<div style="width: 180px;text-align: center;">{{countObj.jczjm}}</div>
					</div>
					<div class="count-li5">
						<div style="width: 50px;text-align: center;">利润</div>
						<div style="width: 180px;text-align: center;">{{countObj.lr}}</div>
					</div>
				</div>
				<div class="hg-one-region">
					<div style="text-align: center;display: flex;height: 50px;line-height: 50px;">
						<div>第一场</div>
						<el-select v-model="value" size="small" style="width: 160px;" placeholder="请选择">
						    <el-option
						      v-for="item in options"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value">
						    </el-option>
						  </el-select>
					</div>
					<div style="margin-top: 15px;">
						平
						<el-input style="width: 80px;margin: 0 10px;" size="small"  v-model = "countObj.hgp1"></el-input>胜/负<el-input
							style="width: 80px;margin-left:10px;" size="small" v-model="countObj.hgsf1"></el-input>
					</div>
					<div style="margin-top: 10px;">
						平
						<el-input style="width: 80px;margin: 0 10px;" size="small" disabled v-model="countObj.hgpjine1"></el-input>胜/负<el-input
							style="width: 80px;margin-left: 10px;" size="small" disabled v-model="countObj.hgsfjine1"></el-input>
					</div>
					<div class="count-li5">
						<div style="width: 115px;text-align: center;">{{countObj.hgfd1}}</div>
						<div style="width: 115px;text-align: center;margin-left: 30px;">{{countObj.hgfd11}}</div>
					</div>
					<div style="height: 30px;line-height: 30px;display: flex;">
						<div style="width: 115px;text-align: center;">{{countObj.hgzjm1}}</div>
						<div style="width: 115px;text-align: center;margin-left: 30px;">{{countObj.hgzjm11}}</div>
					</div>
					<div class="count-li5">
						<div style="width: 115px;text-align: center;" v-if="Number(countObj.hgzjm1) !=0">{{countObj.lr}}</div>
						<div style="width: 115px;text-align: center;" v-else></div>
						<div style="width: 115px;text-align: center;margin-left: 30px;"v-if="Number(countObj.hgzjm11) !=0">{{countObj.lr}}</div>
						<div style="width: 115px;text-align: center;" v-else></div>
					</div>
				</div>
				<div class="hg-two-region">
					<div style="text-align: center;display: flex;height: 50px;line-height: 50px;">
						<div>第二场</div>
							<el-select v-model="value2" size="small" style="width: 160px;" placeholder="请选择">
							    <el-option
							      v-for="item in options"
							      :key="item.value"
							      :label="item.label"
							      :value="item.value">
							    </el-option>
							  </el-select>
					</div>
					<div style="margin-top: 15px;">
						平<el-input style="width: 80px;margin: 0 10px;" size="small"  v-model = "countObj.hgp2"></el-input>胜/负<el-input
							style="width: 80px;margin-left: 10px;" size="small" v-model="countObj.hgsf2"></el-input>
					</div>
					<div style="margin-top: 10px;">
						平<el-input style="width: 80px;margin: 0 10px;" size="small" disabled v-model = "countObj.hgpjine2"></el-input>胜/负<el-input
							style="width: 80px;margin-left: 10px;" size="small" disabled v-model = "countObj.hgsfjine2"></el-input>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div style="width: 115px;text-align: center;">{{countObj.hgfd2}}</div>
						<div style="width: 115px;text-align: center; margin-left: 30px;">{{countObj.hgfd22}}</div>
					</div>
					<div style="height: 30px;line-height: 30px;display: flex;">
						<div style="width: 115px;text-align: center;">{{countObj.hgzjm2}}</div>
						<div style="width: 115px;text-align: center;margin-left: 30px;">{{countObj.hgzjm22}}</div>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div style="width: 115px;text-align: center;" v-if="Number(countObj.hgzjm2) !=0">{{countObj.lr}}</div>
						<div style="width: 115px;text-align: center;" v-else></div>
						<div style="width: 115px;text-align: center;margin-left: 30px;" v-if="Number(countObj.hgzjm22) !=0">{{countObj.lr}}</div>
						<div style="width: 115px;text-align: center;" v-else></div>
					</div>
				</div>
			</div>
		
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">关闭</el-button>
				<el-button type="success" @click="formulaSub">计算</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props:{
			setArr:{
				type:Array,
				required: false,
			}
		},
		data() {
			return {
				weekDay:["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
				pdweek:'',
				countObj:{
					jcpv1:0,
					jcpv2:0,
					jcjine:'1000',
					hgp1:0,
					hgsf1:0,
					hgp2:0,
					hgsf2:0,
					hgpjine1:0,
					hgsfjine1:0,
					hgpjine2:0,
					hgsfjine2:0,
					mc1:0,
					mc2:0,
					f1:0,
					f2:0,
					jcfd:0,
					jczjm:0,
					hgfd1:0,
					hgfd11:0,
					hgzjm1:0,
					hgzjm11:0,
					hgfd2:0,
					hgfd22:0,
					hgzjm2:0,
					hgzjm22:0,
					lr:0
					
				},
				options: [{
				          value: '平&负',
				          label: '平&负'
				        }, {
				          value: '让+0.5',
				          label: '让+0.5'
				        }, {
				          value: '平&让+0.25',
				          label: '平&让+0.25'
				        }, {
				          value: '平&让0',
				          label: '平&让0'
				        }, {
				          value: '平&让-0.25',
				          label: '平&让-0.25'
				        },{
				          value: '平&让-0.5',
				          label: '平&让-0.5'
				        }],
				oneHgping:false,
				twoHgping:false,
				saishi1:{},
				saishi2:{},
				dialogVisible: false,
				dialogArr: [],
				circleUrl: require("@/assets/vator.png"),
				bg1:'width: 120px;background:pink;',
				bg2:'width: 120px;',
				value: '平&负',
				value2:'平&负',
				isOpen: false,
				caifan: '',
				tableData: [],
				resArr: [],
				jieguoArr: []
				//circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
			}
		},
		beforeMount() {
			var dt=new Date();
			this.pdweek = this.weekDay[dt.getDay()]
			//console.log(this.pdweek,'胜平负页面')
		},
		methods:{
			handleClose(done) {
				
				done();
				
				
			},
			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			formula(item) {
				this.countObj={
					jcpv1:0,
					jcpv2:0,
					jcjine:'1000',
					hgp1:0,
					hgsf1:0,
					hgp2:0,
					hgsf2:0,
					hgpjine1:0,
					hgsfjine1:0,
					hgpjine2:0,
					hgsfjine2:0,
					mc1:0,
					mc2:0,
					f1:this.setArr[0].value,
					f2:this.setArr[1].value,
					jcfd:0,
					jczjm:0,
					hgfd1:0,
					hgfd11:0,
					hgzjm1:0,
					hgzjm11:0,
					hgfd2:0,
					hgfd22:0,
					hgzjm2:0,
					hgzjm22:0,
					lr:0
					
				}
				this.dialogVisible = true;
				this.dialogArr = [];
				
				
			},
			async formulaSub(){
				this.countObj.f1 = this.setArr[0].value;
				this.countObj.f2 = this.setArr[1].value;
				//console.log(this.countObj.f1,this.countObj.f2)
				if(this.value == '平&负'){
					this.countObj.mc1 = '胜&平|负';
					if(this.value2 == '平&负'){
						this.countObj.mc2 = '胜&平|负';
					}
					if(this.value2 == '让+0.5'){
						this.countObj.mc2 = '胜&客+0.5';
					}
					if(this.value2 == '平&让+0.25'){
						this.countObj.mc2 = '胜&平|客+0.25';
					}
					if(this.value2 == '平&让0'){
						this.countObj.mc2 = '胜&平|客0';
					}
					if(this.value2 == '平&让-0.25'){
						this.countObj.mc2 = '胜&平|客-0.25';
					}
					if(this.value2 == '平&让-0.5'){
						this.countObj.mc2 = '胜&平|客-0.5';
					}
				}
				if(this.value == '让+0.5'){
					this.countObj.mc1 = '胜&客+0.5';
					if(this.value2 == '平&负'){
						this.countObj.mc2 = '胜&平|负';
					}
					if(this.value2 == '让+0.5'){
						this.countObj.mc2 = '胜&客+0.5';
					}
					if(this.value2 == '平&让+0.25'){
						this.countObj.mc2 = '胜&平|客+0.25';
					}
					if(this.value2 == '平&让0'){
						this.countObj.mc2 = '胜&平|客0';
					}
					if(this.value2 == '平&让-0.25'){
						this.countObj.mc2 = '胜&平|客-0.25';
					}
					if(this.value2 == '平&让-0.5'){
						this.countObj.mc2 = '胜&平|客-0.5';
					}
				}
				if(this.value == '平&让+0.25'){
					this.countObj.mc1 = '胜&平|客+0.25';
					if(this.value2 == '平&负'){
						this.countObj.mc2 = '胜&平|负';
					}
					if(this.value2 == '让+0.5'){
						this.countObj.mc2 = '胜&客+0.5';
					}
					if(this.value2 == '平&让+0.25'){
						this.countObj.mc2 = '胜&平|客+0.25';
					}
					if(this.value2 == '平&让0'){
						this.countObj.mc2 = '胜&平|客0';
					}
					if(this.value2 == '平&让-0.25'){
						this.countObj.mc2 = '胜&平|客-0.25';
					}
					if(this.value2 == '平&让-0.5'){
						this.countObj.mc2 = '胜&平|客-0.5';
					}
				}
				if(this.value == '平&让0'){
					this.countObj.mc1 = '胜&平|客0';
					if(this.value2 == '平&负'){
						this.countObj.mc2 = '胜&平|负';
					}
					if(this.value2 == '让+0.5'){
						this.countObj.mc2 = '胜&客+0.5';
					}
					if(this.value2 == '平&让+0.25'){
						this.countObj.mc2 = '胜&平|客+0.25';
					}
					if(this.value2 == '平&让0'){
						this.countObj.mc2 = '胜&平|客0';
					}
					if(this.value2 == '平&让-0.25'){
						this.countObj.mc2 = '胜&平|客-0.25';
					}
					if(this.value2 == '平&让-0.5'){
						this.countObj.mc2 = '胜&平|客-0.5';
					}
				}
				if(this.value == '平&让-0.25'){
					this.countObj.mc1 = '胜&平|客-0.25';
					if(this.value2 == '平&负'){
						this.countObj.mc2 = '胜&平|负';
					}
					if(this.value2 == '让+0.5'){
						this.countObj.mc2 = '胜&客+0.5';
					}
					if(this.value2 == '平&让+0.25'){
						this.countObj.mc2 = '胜&平|客+0.25';
					}
					if(this.value2 == '平&让0'){
						this.countObj.mc2 = '胜&平|客0';
					}
					if(this.value2 == '平&让-0.25'){
						this.countObj.mc2 = '胜&平|客-0.25';
					}
					if(this.value2 == '平&让-0.5'){
						this.countObj.mc2 = '胜&平|客-0.5';
					}
				}
				if(this.value == '平&让-0.5'){
					this.countObj.mc1 = '胜&平|客-0.5';
					if(this.value2 == '平&负'){
						this.countObj.mc2 = '胜&平|负';
					}
					if(this.value2 == '让+0.5'){
						this.countObj.mc2 = '胜&客+0.5';
					}
					if(this.value2 == '平&让+0.25'){
						this.countObj.mc2 = '胜&平|客+0.25';
					}
					if(this.value2 == '平&让0'){
						this.countObj.mc2 = '胜&平|客0';
					}
					if(this.value2 == '平&让-0.25'){
						this.countObj.mc2 = '胜&平|客-0.25';
					}
					if(this.value2 == '平&让-0.5'){
						this.countObj.mc2 = '胜&平|客-0.5';
					}
				}
				const res = await this.$http({
					path: '/spf/formula',
					method: 'post',
					params: this.countObj
				});
				if(res.data.message == '无效的token'){
					return this.$message.error('登录失效，请重新登录')
				}
				//console.log(res.data,'111222')
				if(res.data.code == 200){
					let ceshi = this.ffym(res.data.result,this.cl)
					let result = JSON.parse(ceshi)
					this.countObj = result
				}
			}
		}
	}
</script>

<style scoped>
	.count-li1{
		display: flex;
		height: 45px;
		line-height: 45px;
		margin-top: 15px;
	}
	.count-li2{
		width: 60px;
		margin: 0 10px;
	}
	.count-li3{
		height: 30px;
		line-height: 30px;
		display: flex;
	}
	.count-li4{
		height: 30px;
		
		width: 150px;
		margin: 0 10px;
	}
	.count-li5{
		height: 45px;
		line-height: 45px;
		display: flex;
	}
	.home{
		height: 100%;
		width: 100%;
	}
	.counter-all {
		
		border-top: 2px solid #0DA969;
		display: flex;
		justify-content: space-between;
		/* //width: 300px; */
		font-size: 12px;
		overflow-x: auto;
		padding-top: 10px;
	}

	.jc-region {
		width: 350px;
		
	}

	.hg-one-region {
		width: 350px;
	}

	.hg-two-region {
		width: 350px;
	}

	.el-container {
		height: 100%;
	}

	.head-set {
		display: flex;
		justify-content: space-between;
	}

	.head-right {
		line-height: 60px;
		color: #409EFF;
		font-size: 25px;
	}

	.el-header {
		background-color: #F8F8F8;

	}

	.logout {
		margin-left: 20px;
		line-height: 35px;
		height: 35px;
		width: 100px;
		border: 1px solid #FF5722;
		text-align: center;
		margin-top: 10px;
		font-size: 13px;
		color: #FF5722;
		border-radius: 12px;
	}

	.el-main {
		background-color: #F8F8F8;
		margin: 0;
		padding: 0;
	}

	.el-aside {
		background-color: #F5F7FB;
	}

	.renovate {
		margin-top: 10px;
		margin-left: 20px;
		font-size: 10px;
	}

	.zd-renovate {
		height: 30px;
		margin-top: 10px;
		margin-left: 20px;
		font-size: 10px;
		margin-right: 20px;

		border-bottom: 1px solid #DCDFE6;
	}

	.el-select {
		width: 70px;
		margin-left: 37px;
	}

	.block {
		margin-top: 40px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.user {
		font-size: 8px;
		text-align: center;
	}

	.name {
		font-size: 12px;
		color: blue;
		font-weight: bold;
		text-align: center;
	}

	.counter {
		margin: 30px 0 10px 20px;
		width: 180px;
	}

	.input-arr {
		font-size: 15px;
		margin-left: 20px;
		margin-top: 10px;
	}

	.play-big {
		margin-top: 100px;
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 30px;
	}

	.set-play {
		width: 100%;
		height: 30px;
		background-color: #0DA969;
		font-size: 15px;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #F5F7FB;
	}

	.new-spf {
		margin-top: 5px;
		width: 100%;
		height: 30px;
		border: 1px solid #0DA969;
		font-size: 15px;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #0DA969;
	}

	.dg {
		margin-top: 5px;
		width: 100%;
		height: 30px;
		border: 1px solid #409EFF;
		font-size: 15px;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #409EFF;
	}

	.new-dg {
		margin-top: 5px;
		width: 100%;
		height: 30px;
		border: 1px solid #409EFF;
		font-size: 15px;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #409EFF;
	}

	.big {
		margin-top: 5px;
		width: 100%;
		height: 30px;
		border: 2px solid #F0D6B0;
		font-size: 15px;
		font-weight: bold;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #F0D6B0;

	}

	.small {
		margin-top: 5px;
		width: 100%;
		height: 30px;
		border: 2px solid #F0D6B0;
		font-size: 15px;
		font-weight: bold;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #F0D6B0;
	}

	.jc-bj {
		display: flex;
		justify-content: space-around;
		font-size: 16px;
	}
	.ss-sty{
		font-size: 16px;
	}
	.jg-bj {
		margin: 20px;
	}
</style>