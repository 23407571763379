import { render, staticRenderFns } from "./bigSmall.vue?vue&type=template&id=7b33079d&scoped=true&"
import script from "./bigSmall.vue?vue&type=script&lang=js&"
export * from "./bigSmall.vue?vue&type=script&lang=js&"
import style0 from "./bigSmall.vue?vue&type=style&index=0&id=7b33079d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b33079d",
  null
  
)

export default component.exports