<template>
	<div class="home">
		<div :class="showTable?'show-table-box2':'show-table-box'" @click="showOrHidden">{{showContent}}</div>
		<el-table ref="multipleTable" border :header-cell-style="{textAlign: 'center',  }" stripe
			:data="tableData2" tooltip-effect="dark" style="width: 99%" 
			@selection-change="handleSelectionChange" v-show="showTable">
			<el-table-column align="center" type="selection" width="55">
			</el-table-column>
			<el-table-column align="center" prop="saishi" label="赛事(时间)" show-overflow-tooltip>
				<template slot-scope="scope">
					<div class="ss-sty">{{scope.row.saishi.cc}}</div>
					<div class="ss-sty">{{scope.row.saishi.date}}</div>
					<div class="ss-sty">{{scope.row.saishi.jcName}}</div>
					<div class="ss-sty">{{scope.row.saishi.hgName}}</div>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="jc" label="彩票(让胜平负)" width="450">
				<template slot-scope="scope">
					<div></div>
					<div class="jc-bj" v-for="(item2,i) in scope.row.jc" :key="i">
						<div style="width: 90px;">{{item2['让球']}}</div>
						<div style="width: 120px;">{{item2['胜']}}</div>
						<div style="width: 120px;">{{item2['平']}}</div>
						<div style="width: 120px;">{{item2['负']}}</div>
					</div>
		
					<div></div>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="hg" label="皇冠(让胜平负)" width="450">
				<template slot-scope="scope">
					<div class="jc-bj" v-for="(item2,i) in scope.row.hg" :key="i">
						<div style="width: 90px;">{{item2['让球']}}</div>
						<div style="width: 120px;">{{item2['胜']}}</div>
						<div style="width: 120px;">{{item2['平']}}</div>
						<div style="width: 120px;">{{item2['负']}}</div>
					</div>
					<div class="jc-bj" v-if="scope.row.hg.length<4">
						<div style="width: 90px;">-</div>
						<div style="width: 120px;">-</div>
						<div style="width: 120px;">-</div>
						<div style="width: 120px;">-</div>
					</div>
		
				</template>
			</el-table-column>
		</el-table>
		<div class="sg-tj-box">方案数量:<span style="color: orangered;">{{jieguoArr2.length}}</span> </div>
		<div class="jg-bj" v-for="(item,i) in jieguoArr2" :key="item.id">
			<div class="">
				投注方案{{i+1}}
			</div>
			<div class="">
				{{item['第一场']['赛事编号']}}---{{item['第一场']['竞彩说明']}},{{item['第一场']['皇冠说明']}}
			</div>
			<div class="">
				{{item['第二场']['赛事编号']}}---{{item['第二场']['竞彩说明']}},{{item['第二场']['皇冠说明']}}
			</div>
			<div class="">
				方案结果：{{item['profit']}}
				<el-button type="success" size="mini" round @click="formula(item)">计算</el-button>
			</div>
		</div>
		<!-- :top="15"  -->
		<el-dialog title="投注计算器" 
		:visible.sync="dialogVisible" 
		v-dialogDrag
		:modal="false"
		
		width="55%" 
		:before-close="handleClose">
			<!-- 计算界面 -->
			<div class="counter-all">
				<div class="jc-region">
					<div style="text-align: center;">彩票</div>
					<div class="count-li1">
						<div style="width: 50px;text-align: center;">赔率 </div>
						<el-input class="count-li2" size="small" v-model="countObj.jcpv1"></el-input> X <el-input
							class="count-li2" size="small" v-model="countObj.jcpv2"></el-input>
					</div>
					<div class="count-li3">
						<div style="width: 50px;text-align: center;">投注</div>
						<el-input class="count-li4" size="mini" v-model="countObj.jcjine"></el-input>
					</div>
					<div class="count-li5">
						<div style="width: 50px;text-align: center;">返点</div> 
						<div style="width: 180px;text-align: center;">{{countObj.jcfd}}</div>
					</div>
					<div style="height: 30px;line-height: 30px;display: flex;">
						<div style="width: 50px;text-align: center;">中将金额</div>
						<div style="width: 180px;text-align: center;">{{countObj.jczjm}}</div>
					</div>
					<div class="count-li5">
						<div style="width: 50px;text-align: center;">利润</div>
						<div style="width: 180px;text-align: center;">{{countObj.lr}}</div>
					</div>
				</div>
				<div class="hg-one-region">
					<div style="text-align: center;">
						第一场
					</div>
					<div style="margin-top: 15px;">
						平
						<el-input style="width: 80px;margin: 0 10px;" size="small" :disabled="oneHgping" v-model = "countObj.hgp1"></el-input>胜/负<el-input
							style="width: 80px;margin-left:10px;" size="small" v-model="countObj.hgsf1"></el-input>
					</div>
					<div style="margin-top: 10px;">
						平
						<el-input style="width: 80px;margin: 0 10px;" size="small" disabled v-model="countObj.hgpjine1"></el-input>胜/负<el-input
							style="width: 80px;margin-left: 10px;" size="small" disabled v-model="countObj.hgsfjine1"></el-input>
					</div>
					<div class="count-li5">
						<div style="width: 115px;text-align: center;">{{countObj.hgfd1}}</div>
						<div style="width: 115px;text-align: center;margin-left: 30px;">{{countObj.hgfd11}}</div>
					</div>
					<div style="height: 30px;line-height: 30px;display: flex;">
						<div style="width: 115px;text-align: center;">{{countObj.hgzjm1}}</div>
						<div style="width: 115px;text-align: center;margin-left: 30px;">{{countObj.hgzjm11}}</div>
					</div>
					<div class="count-li5">
						<div style="width: 115px;text-align: center;" v-if="Number(countObj.hgzjm1) !=0">{{countObj.lr}}</div>
						<div style="width: 115px;text-align: center;" v-else></div>
						<div style="width: 115px;text-align: center;margin-left: 30px;"v-if="Number(countObj.hgzjm11) !=0">{{countObj.lr}}</div>
						<div style="width: 115px;text-align: center;" v-else></div>
					</div>
				</div>
				<div class="hg-two-region">
					<div style="text-align: center;">
						第二场	
					</div>
					<div style="margin-top: 15px;">
						平<el-input style="width: 80px;margin: 0 10px;" size="small" :disabled="twoHgping" v-model = "countObj.hgp2"></el-input>胜/负<el-input
							style="width: 80px;margin-left: 10px;" size="small" v-model="countObj.hgsf2"></el-input>
					</div>
					<div style="margin-top: 10px;">
						平<el-input style="width: 80px;margin: 0 10px;" size="small" disabled v-model = "countObj.hgpjine2"></el-input>胜/负<el-input
							style="width: 80px;margin-left: 10px;" size="small" disabled v-model = "countObj.hgsfjine2"></el-input>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div style="width: 115px;text-align: center;">{{countObj.hgfd2}}</div>
						<div style="width: 115px;text-align: center; margin-left: 30px;">{{countObj.hgfd22}}</div>
					</div>
					<div style="height: 30px;line-height: 30px;display: flex;">
						<div style="width: 115px;text-align: center;">{{countObj.hgzjm2}}</div>
						<div style="width: 115px;text-align: center;margin-left: 30px;">{{countObj.hgzjm22}}</div>
					</div>
					<div style="height: 45px;line-height: 45px;display: flex;">
						<div style="width: 115px;text-align: center;" v-if="Number(countObj.hgzjm2) !=0">{{countObj.lr}}</div>
						<div style="width: 115px;text-align: center;" v-else></div>
						<div style="width: 115px;text-align: center;margin-left: 30px;" v-if="Number(countObj.hgzjm22) !=0">{{countObj.lr}}</div>
						<div style="width: 115px;text-align: center;" v-else></div>
					</div>
				</div>
			</div>
		
		
			<!-- 表格界面 -->
			<el-table ref="multipleTable" border :header-cell-style="{textAlign: 'center'}" stripe :data="dialogArr"
				tooltip-effect="dark" style="width: 99%"  @selection-change="handleSelectionChange">
		
		
				<el-table-column align="center" prop="saishi" label="赛事(时间)" show-overflow-tooltip>
					<template slot-scope="scope">
						<div>{{scope.row.saishi.cc}}</div>
						<div>{{scope.row.saishi.date}}</div>
						<div>{{scope.row.saishi.jcName}}</div>
						<div>{{scope.row.saishi.hgName}}</div>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="jc" label="彩票(让胜平负)" width="280">
					<template slot-scope="scope">
						<div></div>
						<div class="jc-bj" v-for="(item2,i) in scope.row.jc" :key="i">
							<div style="width: 90px;">{{item2['让球']}}</div>
							<div :style="item2['胜样式']?item2['胜样式']:bg2">{{item2['胜']}}</div>
							<div style="width: 120px;">{{item2['平']}}</div>
							<div :style="item2['负样式']?item2['负样式']:bg2">{{item2['负']}}</div>
						</div>
		
						<div></div>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="hg" label="皇冠(让胜平负)" width="280">
					<template slot-scope="scope">
						<div class="jc-bj" v-for="(item2,i) in scope.row.hg" :key="i">
							<div style="width: 90px;">{{item2['让球']}}</div>
							<div :style="item2['胜样式']?item2['胜样式']:bg2">{{item2['胜']}}</div>
							<div :style="item2['平样式']?item2['平样式']:bg2">{{item2['平']}}</div>
							<div :style="item2['负样式']?item2['负样式']:bg2">{{item2['负']}}</div>
						</div>
						<div class="jc-bj" v-if="scope.row.hg.length<4">
							<div style="width: 90px;">-</div>
							<div style="width: 120px;">-</div>
							<div style="width: 120px;">-</div>
							<div style="width: 120px;">-</div>
						</div>
		
						
					</template>
				</el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">关闭</el-button>
				<el-button type="success" @click="formulaSub">计算</el-button>
			</span>
		</el-dialog>
		
		
		
		
	</div>
	
</template>

<script>
	export default {
		name:'home',
		props:{
			setArr:{
				type:Array,
				required: false,
			}
		},
		data() {
			return {
				weekDay:["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
				pdweek:'',
				pdTmweek:'',
				showTable:true,
				showContent:'隐藏赛事',
				countObj:{
					jcpv1:'',
					jcpv2:'',
					jcjine:'10000',
					hgp1:'',
					hgsf1:'',
					hgp2:'',
					hgsf2:'',
					hgpjine1:'',
					hgsfjine1:'',
					hgpjine2:'',
					hgsfjine2:'',
					mc1:'',
					mc2:'',
					f1:'',
					f2:'',
					jcfd:'',
					jczjm:'',
					hgfd1:'',
					hgfd11:'',
					hgzjm1:'',
					hgzjm11:'',
					hgfd2:'',
					hgfd22:'',
					hgzjm2:'',
					hgzjm22:'',
					lr:''
					
				},
				
				oneHgping:false,
				twoHgping:false,
				saishi1:{},
				saishi2:{},
				dialogVisible: false,
				dialogArr: [],
				circleUrl: require("@/assets/vator.png"),
				bg1:'width: 120px;background:pink;',
				bg2:'width: 120px;',
				value: '5',
				isOpen: false,
				caifan: '',
				tableData: [],
				tableData2:[],
				resArr: [],
				jieguoArr: [],
				jieguoArr2:[]
				//circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
			}
		},
		methods: {
			showOrHidden(){
				if(this.showTable == false){
					this.showTable = true
				}else{
					this.showTable = false
				}
				
				if(this.showTable){
					this.showContent = '隐藏赛事'
				}else{
					this.showContent = '显示赛事'
				}
			},
			async getData(){
				//console.log(this.setArr[0].time,111)
				var dt=new Date();
				this.pdweek = this.weekDay[dt.getDay()]
				this.jieguoArr = []
				
				const res = await this.$http({
					path: '/spf/count',
					method: 'post',
					params: {
						"cf": this.setArr[0].value,
						"wf": this.setArr[1].value,
						"money": 100,
						"gl": this.setArr[2].value,
						"time":Number(this.setArr[0].time)
					}
				});
				if(res.data.message == '无效的token'){
					return this.$message.error('登录失效，请重新登录')
				}
				// console.log(res,111)
				// return
				if (res.data.code == '200') {
					//console.log(this.cl,123)
					let ceshi = this.ffym(res.data.result,this.cl)
					let result = JSON.parse(ceshi)
					//console.log(ceshi,123456)
					//return
					//console.log(res.data.result, '111')
					//console.log(this.setArr[0].ssgl,'过滤赛事')
					this.tableData = result['赛事'];
					this.resArr = result['结果'];
					//console.log(this.resArr)
					this.jieguoArr = []
					for (let i = 0; i < this.resArr.length; i++) {
						let hgOnezk = '';
						let hgTwozk = '';
						let hgOnep = '';
						let hgTwop = '';
						let hgOnerq = '';
						let hgTworq = '';
						let lsArr = this.resArr[i]['第一场']['名称'].split('&')
						let jcmcStr1 = '竞彩让球:' + this.resArr[i]['第一场']['竞彩让球'] + ' ' + lsArr[0] + ' 赔率:' + this.resArr[i][
							'第一场'
						]['竞彩赔率'];
						let hgmcStr1 = ''
						if (lsArr[1].indexOf('|') !== -1) {
							let hgstrArr = lsArr[1].split('|');
							hgOnep = '平'
							if(hgstrArr[1].indexOf('主') !==-1 || hgstrArr[1].indexOf('胜') !==-1){
								hgOnezk = '胜'
							}else{
								hgOnezk = '负'
							}
							if(this.resArr[i]['第一场']['竞彩让球'] == '0'){
								hgmcStr1 = '皇冠平赔率:' + this.resArr[i]['第一场']['平赔率'] + '  ' + hgstrArr[1] + '赔率:' + this.resArr[i]['第一场']['皇冠赔率'];
							}else{
								hgmcStr1 = '皇冠平赔率:' + this.resArr[i]['第一场']['平赔率'] + '  '+'皇冠球数:' +this.resArr[i]['第一场']['球数']+hgOnezk+this.resArr[i]['第一场']['皇冠赔率'];
							}
							
						} else {
							hgOnep = '';
							if(lsArr[1].indexOf('主') !==-1 || lsArr[1].indexOf('胜') !==-1){
								hgOnezk = '胜'
							}else{
								hgOnezk = '负'
							}
							if(this.resArr[i]['第一场']['竞彩让球'] == '0'){
								hgmcStr1 = '皇冠' + lsArr[1] + '赔率:' + this.resArr[i]['第一场']['皇冠赔率']
							}else{
								hgmcStr1 = '皇冠球数:' + this.resArr[i]['第一场']['球数']+hgOnezk+this.resArr[i]['第一场']['皇冠赔率'];
							}
							
							
						}
				
						let lsArr2 = this.resArr[i]['第二场']['名称'].split('&');
						let jcmcStr2 = '竞彩让球' + this.resArr[i]['第二场']['竞彩让球'] + '  ' + lsArr2[0] + '赔率' + this.resArr[i]['第二场']['竞彩赔率'];
						let hgmcStr2 = ''
						if (lsArr2[1].indexOf('|') !== -1) {
							let hgstrArr2 = lsArr2[1].split('|');
							hgTwop = '平'
							if(hgstrArr2[1].indexOf('主') !==-1 || hgstrArr2[1].indexOf('胜') !==-1){
								hgTwozk = '胜'
							}else{
								hgTwozk = '负'
							}
							
							if(this.resArr[i]['第二场']['竞彩让球'] == '0'){
								hgmcStr2 = '皇冠平赔率:' + this.resArr[i]['第二场']['平赔率'] + '  ' + hgstrArr2[1] + '赔率:' + this.resArr[i]['第二场']['皇冠赔率'];
							}else{
								
								hgmcStr2 = '皇冠平赔率:' + this.resArr[i]['第二场']['平赔率'] + '  '+'皇冠球数:' +this.resArr[i]['第二场']['球数']+hgTwozk+this.resArr[i]['第二场']['皇冠赔率'];
							}
							
						} else {
							hgTwop = ''
							if(lsArr2[1].indexOf('主') !==-1 || lsArr2[1].indexOf('胜') !==-1){
								hgTwozk = '胜'
							}else{
								hgTwozk = '负'
							}
							if(this.resArr[i]['第二场']['竞彩让球'] == '0'){
								hgmcStr2 = '皇冠' + lsArr2[1] + '赔率:' + this.resArr[i]['第二场']['皇冠赔率']
							}else{
								hgmcStr2 = '皇冠球数:' + this.resArr[i]['第二场']['球数']+hgTwozk+this.resArr[i]['第二场']['皇冠赔率'];
							}
							
						}
						
						hgOnerq = this.resArr[i]['第一场']['球数'];
						hgTworq = this.resArr[i]['第二场']['球数'];
						let profit = (Number(this.resArr[i]['profit']) * 100).toFixed(2) + '%';
						this.saishi1 = {};
						this.saishi2 = {};
						
						for (let i2 = 0; i2 < this.tableData.length; i2++) {
							if (this.resArr[i]['第一场']['赛事编号'] == this.tableData[i2].saishi.cc) {
								this.saishi1 = JSON.parse(JSON.stringify(this.tableData[i2]))
							}
							if (this.resArr[i]['第二场']['赛事编号'] == this.tableData[i2].saishi.cc) {
								this.saishi2 = JSON.parse(JSON.stringify(this.tableData[i2]))
							}
						}
						
						
						
						
						
						var skb1 = JSON.parse(JSON.stringify(this.saishi1));
						var skb2 = JSON.parse(JSON.stringify(this.saishi2));
						
						for(let s = 0;s<skb1.jc.length;s++){
							if(skb1.jc[s]['让球'] == this.resArr[i]['第一场']['竞彩让球']){
								if(lsArr[0] == '胜'){
									skb1.jc[s]['胜样式'] = 'width: 120px;background:pink;';
									skb1.jc[s]['平样式'] = 'width: 120px;';
									skb1.jc[s]['负样式'] = 'width: 120px;';
								}else{
									skb1.jc[s]['胜样式'] = 'width: 120px;';
									skb1.jc[s]['平样式'] = 'width: 120px;';
									skb1.jc[s]['负样式'] = 'width: 120px;background:pink;';
								}
							}
							
						}
						for(let s = 0;s<skb2.jc.length;s++){
							if(skb2.jc[s]['让球'] == this.resArr[i]['第二场']['竞彩让球']){
								if(lsArr2[0] == '胜'){
									skb2.jc[s]['胜样式'] = 'width: 120px;background:pink;';
									skb2.jc[s]['平样式'] = 'width: 120px;';
									skb2.jc[s]['负样式'] = 'width: 120px;';
								}else{
									skb2.jc[s]['胜样式'] = 'width: 120px;';
									skb2.jc[s]['平样式'] = 'width: 120px;';
									skb2.jc[s]['负样式'] = 'width: 120px;background:pink;';
								}
							}
							
						}
						//hg样式添加
						skb1.hg.forEach(item=>{
							if(hgOnep !== '' && item['平'] !=='-'){
								item['平样式'] = 'width: 120px;background:pink;';
							}
							if(item['让球'] == hgOnerq){
								if(hgOnezk == '胜'){
									item['胜样式'] = 'width: 120px;background:pink;'
								}
								if(hgOnezk == '负'){
									item['负样式'] = 'width: 120px;background:pink;'
								}
							}
						})
						skb2.hg.forEach(item=>{
							if(hgTwop !== '' && item['平'] !=='-'){
								item['平样式'] = 'width: 120px;background:pink;';
							}
							if(item['让球'] == hgTworq){
								if(hgTwozk == '胜'){
									item['胜样式'] = 'width: 120px;background:pink;'
								}
								if(hgTwozk == '负'){
									item['负样式'] = 'width: 120px;background:pink;'
								}
							}
						})
						if(i==0){
							//console.log(skb2,hgTwozk,hgTworq)
						}
						let jcpl1 = this.resArr[i]['第一场']['竞彩赔率'];
						let jcpl2 = this.resArr[i]['第二场']['竞彩赔率'];
						let hgpl1 = this.resArr[i]['第一场']['皇冠赔率'];
						let hgpl2 = this.resArr[i]['第二场']['皇冠赔率'];
						let hgping1 = '';
						let hgping2 = '';
							
						if(this.resArr[i]['第一场']['平赔率']){
							hgping1 = this.resArr[i]['第一场']['平赔率'];
						}
						if(this.resArr[i]['第二场']['平赔率']){
							hgping2 = this.resArr[i]['第二场']['平赔率'];
						}
						
						
						
						let plObj = {
							jcpv1:jcpl1,
							jcpv2:jcpl2,
							hgp1:hgping1,
							hgsf1:hgpl1,
							hgp2:hgping2,
							hgsf2:hgpl2,
							jcjine:'10000',
							hgpjine1:'',
							hgsfjine1:'',
							hgpjine2:'',
							hgsfjine2:'',
							mc1:this.resArr[i]['第一场']['名称'],
							mc2:this.resArr[i]['第二场']['名称'],
							f1:this.setArr[0].value,
							f2:this.setArr[1].value,
							jcfd:'',
							jczjm:'',
							hgfd1:'',
							hgfd11:'',
							hgzjm1:'',
							hgzjm11:'',
							hgfd2:'',
							hgfd22:'',
							hgzjm2:'',
							hgzjm22:'',
							lr:''
						}
						let newJson = {
							id: i + profit,
							profit: profit,
							plobj:plObj,
							'第一场': {
								'赛事编号': this.resArr[i]['第一场']['赛事编号'],
								'竞彩说明': jcmcStr1,
								'皇冠说明': hgmcStr1,
								
							},
							'第二场': {
								'赛事编号': this.resArr[i]['第二场']['赛事编号'],
								'竞彩说明': jcmcStr2,
								'皇冠说明': hgmcStr2,
				
							},
							'赛事': [skb1,skb2]
						}
						let jcplA = jcpl1;
						let jcplB = jcpl2;
						let hgplA = hgpl1;
						let hgplB = hgpl2;
						let hgplPa = hgping1;
						let hgplPb = hgping2;
						let plOne = this.setArr[0].plOne;
						let plTwo = this.setArr[0].plTwo;
						let plZjz = (Number(plOne)+Number(plTwo))/2
						if(jcplA == ""){
							jcplA = plZjz
						}
						if(jcplB == ""){
							jcplB = plZjz
						}
						if(hgplA == ""){
							hgplA = plZjz
						}
						if(hgplB == ""){
							hgplB = plZjz
						}
						if(hgplPa == ""){
							hgplPa = plZjz
						}
						if(hgplPb == ""){
							hgplPb = plZjz
						}
						if(Number(jcplA)*Number(jcplB) >=plOne && Number(jcplA)*Number(jcplB) <=plTwo){
							this.jieguoArr.push(newJson)
						}
						// if(jcplA>= plOne && jcplA<=plTwo && jcplB>= plOne && jcplB<=plTwo && hgplA>= plOne && hgplA<=plTwo  && hgplB>= plOne && hgplB<=plTwo && hgplPa>= plOne && hgplPa<=plTwo && hgplPb>= plOne && hgplPb<=plTwo){
						// 	this.jieguoArr.push(newJson)
						// }
						
					}
					
					//console.log(this.jieguoArr)
				
					//console.log(res.data.result)
				}
				//console.log(this.pdweek,this.setArr[0].guolv)
				//console.log(this.pdweek)
				this.tableData = this.tableData.filter((item)=>{
					return this.setArr[0].ssgl.indexOf(item.saishi.cc) ==-1;
				})
				this.jieguoArr = this.jieguoArr.filter((item) => {
					//console.log(item)
					return this.setArr[0].ssgl.indexOf(item['第一场']['赛事编号']) ==-1 
				  //return item['第一场']['赛事编号'].indexOf(this.pdweek) !==-1 && item['第二场']['赛事编号'].indexOf(this.pdweek) !==-1;
				});
				this.jieguoArr = this.jieguoArr.filter((item) => {
					//console.log(item)
					return this.setArr[0].ssgl.indexOf(item['第二场']['赛事编号']) ==-1 
				  //return item['第一场']['赛事编号'].indexOf(this.pdweek) !==-1 && item['第二场']['赛事编号'].indexOf(this.pdweek) !==-1;
				});
				if(this.setArr[0].guolv == 'today'){
					this.tableData = this.tableData.filter((item) => {
						//console.log(item)
					  return item.saishi.cc.indexOf(this.pdweek) !==-1;
					});
					this.jieguoArr = this.jieguoArr.filter((item) => {
						//console.log(item)
					  return item['第一场']['赛事编号'].indexOf(this.pdweek) !==-1 && item['第二场']['赛事编号'].indexOf(this.pdweek) !==-1;
					});
				}
				
				if(this.setArr[0].guolv == 'tm'){
					this.tableData = this.tableData.filter((item) => {
						//console.log(item)
					  return item.saishi.cc.indexOf(this.pdweek) !==-1 || item.saishi.cc.indexOf(this.pdTmweek) !==-1;
					});
					this.jieguoArr = this.jieguoArr.filter((item) => {
						//console.log(item)
					  return item['第二场']['赛事编号'].indexOf(this.pdTmweek) !==-1;
					});
				}
				//console.log(this.tableData)
				this.tableData2 = this.tableData
				//console.log(this.jieguoArr,1212,this.setArr)
				this.jieguoArr2 = this.jieguoArr;
				this.$emit('home',this.jieguoArr2.length);
			},
			handleClose(done) {
				done();
				
			},
			
			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			formula(item) {
				this.dialogVisible = true;
				this.dialogArr = [];
				this.dialogArr = item['赛事'];
				this.countObj = item.plobj;
				//console.log(item.plobj, '111')
				if(this.countObj.hgp1 == ''){
					this.oneHgping = true
				}else{
					this.oneHgping = false
				}
				if(this.countObj.hgp2 == ''){
					this.twoHgping = true
				}else{
					this.twoHgping = false
				}
				
			},
			async formulaSub(){
				const res = await this.$http({
					path: '/spf/formula',
					method: 'post',
					params: this.countObj
				});
				if(res.data.message == '无效的token'){
					return this.$message.error('登录失效，请重新登录')
				}
				//console.log(res.data,'111222')
				if(res.data.code == 200){
					let ceshi = this.ffym(res.data.result,this.cl)
					let result = JSON.parse(ceshi)
					this.countObj = result
				}
			}
		},
		created() {
			var dt=new Date();
			let dtTm = dt.getDay()+1;
			if(dtTm == 7){
				dtTm = 0;
			}
			//console.log(dtTm,1212)
			this.pdweek = this.weekDay[dt.getDay()]
			this.pdTmweek = this.weekDay[dtTm]
			//console.log(this.pdTmweek,this.pdweek)
			//console.log(this.setArr,'接受到的参数')
			this.getData()
			//this.$emit('home',this.jieguoArr.length);
		}
	}
</script>

<style scoped>
	.count-li1{
		display: flex;
		height: 45px;
		line-height: 45px;
		margin-top: 15px;
	}
	.count-li2{
		width: 60px;
		margin: 0 10px;
	}
	.count-li3{
		height: 30px;
		line-height: 30px;
		display: flex;
	}
	.count-li4{
		height: 30px;
		
		width: 150px;
		margin: 0 10px;
	}
	.count-li5{
		height: 45px;
		line-height: 45px;
		display: flex;
	}
	.home{
		height: 100%;
		width: 100%;
	}
	.show-table-box{
		
		height: 35px;
		line-height: 35px;
		margin-left: 20px;
		font-size: 16px;
		width: 120px;
		text-align: center;
		color: #FFFFFF;
		border-radius: 5px;
		background-color: #67C23A;
		margin-bottom: 20px;
	}
	.show-table-box2{
		height: 35px;
		line-height: 35px;
		margin-left: 20px;
		font-size: 16px;
		width: 120px;
		text-align: center;
		color: #FFFFFF;
		border-radius: 5px;
		background-color: #67C23A;
		margin-bottom: 20px;
	}
	.counter-all {
		border-top: 2px solid #0DA969;
		display: flex;
		justify-content: space-between;
		/* //width: 300px; */
		font-size: 12px;
		overflow-x: auto;
		padding-top: 10px;
	}

	.jc-region {
		width: 350px;
		
	}

	.hg-one-region {
		width: 350px;
	}

	.hg-two-region {
		width: 350px;
	}

	.el-container {
		height: 100%;
	}

	.head-set {
		display: flex;
		justify-content: space-between;
	}

	.head-right {
		line-height: 60px;
		color: #409EFF;
		font-size: 25px;
	}

	.el-header {
		background-color: #F8F8F8;

	}

	.logout {
		margin-left: 20px;
		line-height: 35px;
		height: 35px;
		width: 100px;
		border: 1px solid #FF5722;
		text-align: center;
		margin-top: 10px;
		font-size: 13px;
		color: #FF5722;
		border-radius: 12px;
	}

	.el-main {
		background-color: #F8F8F8;
		margin: 0;
		padding: 0;
	}

	.el-aside {
		background-color: #F5F7FB;
	}

	.renovate {
		margin-top: 10px;
		margin-left: 20px;
		font-size: 10px;
	}

	.zd-renovate {
		height: 30px;
		margin-top: 10px;
		margin-left: 20px;
		font-size: 10px;
		margin-right: 20px;

		border-bottom: 1px solid #DCDFE6;
	}

	.el-select {
		width: 70px;
		margin-left: 37px;
	}

	.block {
		margin-top: 40px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.user {
		font-size: 8px;
		text-align: center;
	}

	.name {
		font-size: 12px;
		color: blue;
		font-weight: bold;
		text-align: center;
	}

	.counter {
		margin: 30px 0 10px 20px;
		width: 180px;
	}

	.input-arr {
		font-size: 15px;
		margin-left: 20px;
		margin-top: 10px;
	}

	.play-big {
		margin-top: 100px;
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 30px;
	}

	.set-play {
		width: 100%;
		height: 30px;
		background-color: #0DA969;
		font-size: 15px;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #F5F7FB;
	}

	.new-spf {
		margin-top: 5px;
		width: 100%;
		height: 30px;
		border: 1px solid #0DA969;
		font-size: 15px;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #0DA969;
	}

	.dg {
		margin-top: 5px;
		width: 100%;
		height: 30px;
		border: 1px solid #409EFF;
		font-size: 15px;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #409EFF;
	}

	.new-dg {
		margin-top: 5px;
		width: 100%;
		height: 30px;
		border: 1px solid #409EFF;
		font-size: 15px;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #409EFF;
	}

	.big {
		margin-top: 5px;
		width: 100%;
		height: 30px;
		border: 2px solid #F0D6B0;
		font-size: 15px;
		font-weight: bold;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #F0D6B0;

	}

	.small {
		margin-top: 5px;
		width: 100%;
		height: 30px;
		border: 2px solid #F0D6B0;
		font-size: 15px;
		font-weight: bold;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #F0D6B0;
	}

	.jc-bj {
		display: flex;
		justify-content: space-around;
		font-size: 16px;
	}
	.ss-sty{
		font-size: 16px;
	}
	.jg-bj {
		margin: 20px;
	}
	.sg-tj-box{
		margin-left: 20px;
		height: 40px;
		line-height: 40px;
	}
</style>
