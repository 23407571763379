<template>
	<el-container>
		<el-aside width="220px">
			<el-col :span="24">
				<div class="block">
					<el-avatar :size="50" :src="circleUrl"></el-avatar>
				</div>
				<div class="user">{{username}}</div>
				<div class="name">普通用户</div>
				<div class="counter">
					<el-button style="width: 160px;" type="success" icon="el-icon-notebook-2" @click="counter"> 计算器</el-button>
				</div>
				<div>
					<el-button style="width: 160px;margin-left: 20px;" type="primary" @click="renovate">刷新</el-button>
				</div>
				<div class="td-all">
					<div :class="tdStyle" @click="tdClick">今日</div>
					<div :class="tmStyle" @click="tmClick">今明</div>
					<div :class="allStyle" @click="allClick">所有</div>
				</div>
				<div class="renovate">
					刷新时间
					<el-select v-model="value" placeholder="" size="small">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="zd-renovate">
					自动刷新
					<el-switch style="margin-left: 50px;" v-model="isOpen">
					</el-switch>
				</div>
				<div class="input-arr" v-for="item in setArr" :key="item.id">
					{{item.sm}}：<el-input  v-model="item.value" type="number"
						style="width: 95px;margin-left: 30px;" placeholder="请输入彩反"></el-input>
				</div>
				<div class="jg-time-box">
					赔率范围:(2串1为竞彩相乘)
					<div>
						<el-input  v-model="setArr[0].plOne" type="number"
							style="width: 75px;margin-top: 10px;height: 30px;" placeholder="低赔"></el-input>
						~<el-input  v-model="setArr[0].plTwo" type="number"
								style="width: 75px;margin-top: 10px;height: 30px;" placeholder="高赔"></el-input>
					</div>
				</div>
				
				<div class="jg-time-box">
					2串1间隔时间(小时):
					<el-input  v-model="setArr[0].time" type="number"
						style="width: 160px;margin-top: 10px;height: 30px;" placeholder="请输入间隔时间"></el-input>
				</div>
				<div class="jg-time-box">
					比赛过滤:(多场以逗号隔开)
					<el-input  v-model="setArr[0].ssgl" type=""
						style="width: 160px;margin-top: 10px;height: 30px;" placeholder="周一001,周一002"></el-input>
				</div>
				<div class="play-big">
					<div :class="item.state?item.class:item.fclass" v-for="item in playArr" :key='item.id'
						@click="choose(item)">
						{{item.value}}
					</div>
				</div>
				


			</el-col>


		</el-aside>
		<el-container>
			<el-header>
				<div class="head-set">
					<div style="line-height: 60px">
						彩票最后同步:{{newTime}}
						<span style="padding-left: 20px;">账户到期时间:{{daoqiTime}}</span>
					</div>
					<!-- <div style="line-height: 60px;"></div> -->
					<div style="display: flex;line-height: 60px;">
						<div class="head-right" @click="tsVideo">
							<i :class="isIcon"></i>
							<audio ref="audio" class="aud">
								<source :src="urlMp3" />
							</audio>
							<!-- <audio src="../../../public/tishi.mp3"></audio> -->
						</div>
						<div class="logout" @click="logout">
							退出登录
						</div>
					</div>

				</div>

			</el-header>
			<el-main>
				<!-- :cell-style="columnStyle" -->
				<div v-if="spfState">
					<home :setArr="setArr" ref="homeRef"></home>
				</div>
				<div v-if="dgState">
					<dan-gu :setArr="setArr" ref="dgRef"></dan-gu>
				</div>
				<div v-if="newdgState">
					<new-dan-gu :setArr="setArr" ref="newdgRef"></new-dan-gu>
				</div>
				<div v-if="bmState">
					<big-small :setArr="setArr" ref="bsRef"></big-small>
				</div>
				<div v-if="smallState">
					<small-big :setArr="setArr" ref="smallRef"></small-big>
				</div>
				<div v-if="bqcState">
					<bqc :setArr="setArr" ref="bqcRef"></bqc>
				</div>
				<div v-if="btnewdgState">
					<basket-newdg :setArr="setArr" ref="btnewdgRef"></basket-newdg>
				</div>
				<div v-if="btdgState">
					<basket-dangu :setArr="setArr" ref="btdgRef"></basket-dangu>
				</div>
				<!-- btspfState -->
				<div v-if="btspfState">
					<basket-home :setArr="setArr" ref="bthomeRef"></basket-home>
				</div>
				<bm-formula :setArr="setArr" ref="bmformulaRef"></bm-formula>
				<sb-formula :setArr="setArr" ref="sbformulaRef"></sb-formula>
				<sfp-formula :setArr="setArr" ref="spfformulaRef"></sfp-formula>
				<dg-formula :setArr="setArr" ref="dgformulaRef"></dg-formula>
				<bqc-formula :setArr="setArr" ref="bqcformulaRef"></bqc-formula>
				<btspf-formula :setArr="setArr" ref="btspfformulaRef"></btspf-formula>
				<btdg-formula :setArr="setArr" ref="btdgformulaRef"></btdg-formula>
			</el-main>
		</el-container>

	</el-container>
</template>

<script>
	import home from '@/components/home.vue'
	import danGu from '@/components/danGu.vue'
	import newDanGu from '@/components/newDanGu.vue'
	import bigSmall from '@/components/bigSmall.vue'
	import smallBig from '@/components/smallBig.vue'
	import bmFormula from '@/components/bmFormula.vue'
	import sbFormula from '@/components/sbFormula.vue'
	import sfpFormula from '@/components/spfFormula.vue'
	import dgFormula from '@/components/dgFormula.vue'
	import bqc from '@/components/bqc.vue'
	import bqcFormula from '@/components/bqcFormula.vue'
	import basketDangu from '@/components/basketDangu.vue'
	import basketHome from '@/components/basketHome.vue'
	import basketNewdg from '@/components/basketNewdg.vue'
	import btspfFormula from '@/components/btspfFormula.vue'
	import btdgFormula from '@/components/btdgFormula.vue'
	export default {
		components: {
			home,
			basketDangu,
			basketHome,
			basketNewdg,
			danGu,
			bqc,
			newDanGu,
			bigSmall,
			smallBig,
			bmFormula,
			sfpFormula,
			dgFormula,
			bqcFormula,
			sbFormula,
			btspfFormula,
			btdgFormula
		},
		name:'Parent',
		data() {
			return {
				tdStyle:'all-st',
				tmStyle:'tm-st',
				allStyle:'td-st',
				weekDay:["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
				pdweek:'',
				plOne:1,
				plTwo:10,
				username:'',
				userInfo:{},
				daoqiTime:'',
				ysq:null,
				homeArr:0,
				dgArr:0,
				newdgarr:0,
				btnewdgarr:0,
				btdgarr:0,
				bthomeArr:0,
				bmArr:0,
				smallArr:0,
				urlMp3: '',
				newTime: '',
				spfState: true,
				newdgState: false,
				// bbnewdgState:false,
				// bbdgState:false,
				// bbspfState:false,
				upTimer: null,
				stTimer:null,
				dgState: false,
				bmState: false,
				bqcState:false,
				smallState:false,
				btdgState:false,
				btspfState:false,
				btnewdgState:false,
				isIcon: '',
				icon2: 'el-icon-bell',
				icon1: 'el-icon-message-solid',
				circleUrl: require("@/assets/vator.png"),
				bg1: 'width: 120px;background:pink;',
				bg2: 'width: 120px;',
				value: 5,
				isOpen: false,
				options: [{
					value: 5,
					label: 5
				}, {
					value: 10,
					label: 10
				}, {
					value: 15,
					label: 15
				}, {
					value: 20,
					label: 20
				}, {
					value: 30,
					label: 30
				}],
				caifan: '',
				setArr: [{
						id: 1,
						sm: '彩反',
						value: 12,
						guolv:'all',
						plOne:1,
						plTwo:100,
						time:2,
						ssgl:''
					},
					{
						id: 2,
						sm: '外反',
						value: 2
					},
					{
						id: 3,
						sm: '过滤',
						value: 0
					},
					
					// {
					// 	id: 4,
					// 	sm: '间隔小时',
					// 	value: 2
					// },
					// {
					// 	id: 5,
					// 	sm: '赔率范围',
					// 	value: '1~30'
					// },
				],
				jgTime:2.5,
				plFanwei:'1~10',
				playArr: [{
						id: 1,
						state: true,
						value: '足球2串1',
						class: 'set-play',
						fclass: 'set-play-false'
					},

					{
						id: 3,
						state: false,
						value: '单固',
						class: 'dg',
						fclass: 'dg-false'
					},
					{
						id: 4,
						state: false,
						value: '新全单固',
						class: 'new-dg',
						fclass: 'new-dg-false'
					},
					{
						id: 5,
						state: false,
						value: '大',
						class: 'new-dg',
						fclass: 'new-dg-false'
					},
					{
						id: 6,
						state: false,
						value: '小',
						class: 'new-dg',
						fclass: 'new-dg-false'
					},
					{
						id: 7,
						state: false,
						value: '半全场',
						class: 'new-dg',
						fclass: 'new-dg-false'
					},
					{
						id: 8,
						state: false,
						value: '篮球2串1',
						class: 'new-bb',
						fclass: 'new-bb-false'
					},
					{
						id: 9,
						state: false,
						value: '篮球单固',
						class: 'new-bb',
						fclass: 'new-bb-false'
					},
					{
						id: 10,
						state: false,
						value: '篮球新全单固',
						class: 'new-bb',
						fclass: 'new-bb-false'
					}
					// {
					// 	id: 11,
					// 	state: false,
					// 	value: '篮球新全单固(分差排序)',
					// 	class: 'new-bb',
					// 	fclass: 'new-bb-false'
					// }
				],

				//circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
			}
		},
		watch: {
			isOpen(newvalue, old) {
				if (newvalue == true) {
					this.updateData()
				} else {
					clearInterval(this.upTimer)
				}
				//console.log('老数据为',old)
				//console.log('新数据为',newvalue)
			}
		},
		beforeDestroy() {
			clearInterval(this.upTimer);
			clearInterval(this.stTimer);
		},
		methods: {
			tdClick(){
				this.tdStyle = 'td-st';
				this.tmStyle = 'all-st'
				this.allStyle = 'all-st';
				this.setArr[0].guolv = 'today'
				this.renovate()
			},
			allClick(){
				this.tdStyle = 'all-st';
				this.tmStyle = 'all-st'
				this.allStyle = 'td-st';
				this.setArr[0].guolv = 'all'
				this.renovate()
			},
			tmClick(){
				this.tdStyle = 'all-st';
				this.tmStyle = 'td-st'
				this.allStyle = 'all-st';
				this.setArr[0].guolv = 'tm'
				this.renovate()
			},
			async pdState(){
				this.stTimer = setInterval(async () => {
					const res = await this.$http({
						path: '/user/state',
						method: 'post',
						params: {
							username:this.username
						}
					});
					if(res.data.message == '会员过期'){
						this.logout()
						return
					}
					if(res.data.message == '无效的token'){
						this.logout()
						return this.$message.error('登录失效，请重新登录')
					}
					if(res.data.message == 'token已过期'){
						this.logout()
						return this.$message.error('登录失效，请重新登录')
					}
					//console.log(res.data,'保持登录状态返回的数据')
					var dt=new Date();
					this.pdweek = this.weekDay[dt.getDay()]
					//console.log(this.pdweek)
				}, 12000);
			},
			counter(){
				if(this.bmState){
					this.$refs.bmformulaRef.formula()
				}
				if(this.smallState){
					this.$refs.sbformulaRef.formula()
				}
				if(this.spfState){
					this.$refs.spfformulaRef.formula()
				}
				if(this.dgState){
					this.$refs.dgformulaRef.formula()
				}
				if(this.newdgState){
					this.$refs.dgformulaRef.formula()
				}
				if(this.bqcState){
					this.$refs.bqcformulaRef.formula()
				}
				if(this.btspfState){
					this.$refs.btspfformulaRef.formula()
				}
				if(this.btdgState){
					this.$refs.btdgformulaRef.formula()
				}
				if(this.btnewdgState){
					this.$refs.btdgformulaRef.formula()
				}
			},
			getHomeArr(cs){
				//console.log(cs,'拿到值了没')
				this.homeArr = cs;
				if(Number(this.homeArr) > 0 && this.isIcon == this.icon1){
					let music1 = new Audio(); //建立一个music1对象
					music1 = require("../../../public/tishi2.mp3"); //通过require引入音频
					this.$refs.audio.src = music1; //此处的audio为代码ref="audio"中的audio
					this.$refs.audio.play(); //play()为播放函数
				}
				
			},
			getbtHomeArr(cs){
				this.bthomeArr = cs;
				if(Number(this.bthomeArr) > 0 && this.isIcon == this.icon1){
					let music1 = new Audio(); //建立一个music1对象
					music1 = require("../../../public/tishi2.mp3"); //通过require引入音频
					this.$refs.audio.src = music1; //此处的audio为代码ref="audio"中的audio
					this.$refs.audio.play(); //play()为播放函数
				}
			},
			getDgArr(cs){
				this.dgArr = cs;
				if(Number(this.dgArr) > 0 && this.isIcon == this.icon1){
					let music1 = new Audio(); //建立一个music1对象
					music1 = require("../../../public/tishi6.mp3"); //通过require引入音频
					this.$refs.audio.src = music1; //此处的audio为代码ref="audio"中的audio
					this.$refs.audio.play(); //play()为播放函数
				}
				
			},
			getNewDgArr(cs){
				this.newdgarr = cs;
				if(Number(this.newdgarr) > 0 && this.isIcon == this.icon1){
					let music1 = new Audio(); //建立一个music1对象
					music1 = require("../../../public/newdg.mp3"); //通过require引入音频
					this.$refs.audio.src = music1; //此处的audio为代码ref="audio"中的audio
					this.$refs.audio.play(); //play()为播放函数
				}
				
			},
			//篮球新全单固
			// getbtNewDgArr(cs){
			// 	this.btnewdgarr = cs;
			// 	if(Number(this.btnewdgarr) > 0 && this.isIcon == this.icon1){
			// 		let music1 = new Audio(); //建立一个music1对象
			// 		music1 = require("../../../public/newdg.mp3"); //通过require引入音频
			// 		this.$refs.audio.src = music1; //此处的audio为代码ref="audio"中的audio
			// 		this.$refs.audio.play(); //play()为播放函数
			// 	}
				
			// },
			getbtNewDgArr(cs){
				this.btnewdgarr = cs;
				if(Number(this.btnewdgarr) > 0 && this.isIcon == this.icon1){
					let music1 = new Audio(); //建立一个music1对象
					music1 = require("../../../public/newdg.mp3"); //通过require引入音频
					this.$refs.audio.src = music1; //此处的audio为代码ref="audio"中的audio
					this.$refs.audio.play(); //play()为播放函数
				}
				
			},
			getbtdgArr(cs){
				this.btdgarr = cs;
				if(Number(this.btdgarr) > 0 && this.isIcon == this.icon1){
					let music1 = new Audio(); //建立一个music1对象
					music1 = require("../../../public/newdg.mp3"); //通过require引入音频
					this.$refs.audio.src = music1; //此处的audio为代码ref="audio"中的audio
					this.$refs.audio.play(); //play()为播放函数
				}
			},
			getbmArr(cs){
				this.bmArr = cs;
				if(Number(this.bmArr) > 0 && this.isIcon == this.icon1){
					let music1 = new Audio(); //建立一个music1对象
					music1 = require("../../../public/dxq.mp3"); //通过require引入音频
					this.$refs.audio.src = music1; //此处的audio为代码ref="audio"中的audio
					this.$refs.audio.play(); //play()为播放函数
				}
				
			},
			getsmallArr(cs){
				this.smallArr = cs;
				if(Number(this.smallArr) > 0 && this.isIcon == this.icon1){
					let music1 = new Audio(); //建立一个music1对象
					music1 = require("../../../public/dxq.mp3"); //通过require引入音频
					this.$refs.audio.src = music1; //此处的audio为代码ref="audio"中的audio
					this.$refs.audio.play(); //play()为播放函数
				}
				
			},
			getbqcArr(cs){
				//console.log(cs,123)
				this.bmArr = cs;
				if(Number(this.bmArr) > 0 && this.isIcon == this.icon1){
					let music1 = new Audio(); //建立一个music1对象
					
					music1 = require("../../../public/bqc.mp3"); //通过require引入音频
					this.$refs.audio.src = music1; //此处的audio为代码ref="audio"中的audio
					this.$refs.audio.play(); //play()为播放函数
				}
				
			},
			tsVideo() {
				//console.log('点击了提示音')
				if (this.isIcon == this.icon1) {
					this.isIcon = this.icon2
				} else {
					this.isIcon = this.icon1;

				}
			},
			renovate() {
				if (this.bqcState) {
					this.ysq = setTimeout(()=>{
						this.$refs.bqcRef.getNewData()
						this.getNowTime()
						//getNewDgArr
						this.$refs.bqcRef.$on('bqc', this.getbqcArr);
						clearTimeout(this.ysq)
					},300)
					
					//this.reload()
					//console.log(this.newdgarr,'子组件传来的值是多少')
					
				}
				
				if (this.spfState) {
					this.ysq = setTimeout(()=>{
						this.$refs.homeRef.getData()
						this.getNowTime()
						this.$refs.homeRef.$on('home', this.getHomeArr);
						clearTimeout(this.ysq)
					},300)
					
					//console.log(this.homeArr,'home子组件传来的值')
					//this.reload()
					
					
				}
				//篮球胜平负
				if (this.btspfState) {
					this.ysq = setTimeout(()=>{
						this.$refs.bthomeRef.getData()
						this.getNowTime()
						this.$refs.bthomeRef.$on('bthome', this.getbtHomeArr);
						clearTimeout(this.ysq)
					},300)
					
					//console.log(this.homeArr,'home子组件传来的值')
					//this.reload()
					
					
				}
				if (this.dgState) {
					this.ysq = setTimeout(()=>{
						this.$refs.dgRef.getdgData()
						this.getNowTime()
						this.$refs.dgRef.$on('dg', this.getDgArr);
						clearTimeout(this.ysq)
					},300)
					
					//this.reload()
					//console.log(this.homeArr,'home子组件传来的值')
					
				}
				if (this.newdgState) {
					this.ysq = setTimeout(()=>{
						this.$refs.newdgRef.getNewData()
						this.getNowTime()
						//getNewDgArr
						this.$refs.newdgRef.$on('newdg', this.getNewDgArr);
						clearTimeout(this.ysq)
					},300)
					
					//this.reload()
					//console.log(this.newdgarr,'子组件传来的值是多少')
					
				}
				//篮球新全单固
				if (this.btnewdgState) {
					this.ysq = setTimeout(()=>{
						this.$refs.btnewdgRef.getNewData()
						this.getNowTime()
						//getNewDgArr
						this.$refs.btnewdgRef.$on('newdg', this.getbtNewDgArr);
						clearTimeout(this.ysq)
					},300)
					
					//this.reload()
					//console.log(this.newdgarr,'子组件传来的值是多少')
					
				}
				//篮球单固
				//篮球单固
				if (this.btdgState) {
					this.ysq = setTimeout(()=>{
						this.$refs.btdgRef.getNewData()
						this.getNowTime()
						//getNewDgArr
						this.$refs.btdgRef.$on('bbdg', this.getbtdgArr);
						clearTimeout(this.ysq)
					},300)
					
					//this.reload()
					//console.log(this.newdgarr,'子组件传来的值是多少')
					
				}
				if (this.bmState) {
					this.ysq = setTimeout(()=>{
						this.$refs.bsRef.getbmData()
						this.getNowTime();
						this.$refs.bsRef.$on('bm', this.getbmArr);
						clearTimeout(this.ysq)
					},300)
					
					//this.reload()
					
				}
				if (this.smallState) {
					this.ysq = setTimeout(()=>{
						this.$refs.smallRef.getsmallData()
						this.getNowTime()
						//getNewDgArr
						this.$refs.smallRef.$on('small', this.getsmallArr);
						clearTimeout(this.ysq)
					},300)
					
					//this.reload()
					//console.log(this.newdgarr,'子组件传来的值是多少')
					
				}
			},
			updateData() {
				//this.clearTimer(); //关闭定时器

				this.upTimer = setInterval(() => {
					this.renovate()
					//this.getNowTime()
					// if(this.isIcon == this.icon1 && this.homeArr>0){
					// 	let music1 = new Audio(); //建立一个music1对象
						
					// 	music1 = require("../../../public/tishi2.mp3"); //通过require引入音频
						
					// 	this.$refs.audio.src = music1; //此处的audio为代码ref="audio"中的audio
						
					// 	this.$refs.audio.play(); //play()为播放函数
					// }
					
				}, this.value * 1000);


			},
			choose(item) {
				if (item.value == '足球2串1') {
					this.spfState = true;
					this.getNowTime();
					
					this.newdgState = false;
					this.dgState = false;
					this.bmState = false;
					this.bqcState = false;
					this.smallState = false;
					this.btspfState = false;
					this.btnewdgState = false;
					this.btdgState = false;
					this.playArr[8].state = false;
					
					this.playArr[7].state = false;
					this.playArr[6].state = false;
					this.playArr[0].state = true;
					this.playArr[1].state = false;
					this.playArr[2].state = false;
					this.playArr[3].state = false;
					this.playArr[4].state = false;
					this.playArr[5].state = false;
					this.renovate()
					//this.$refs.homeRef.$on('home', this.getHomeArr);
				}
				if (item.value == '单固') {
					this.spfState = false;
					this.newdgState = false;
					this.bqcState = false;
					this.dgState = true;
					this.smallState = false;
					this.getNowTime()
					
					this.bmState = false;
					this.btspfState = false;
					this.btnewdgState = false;
					this.btdgState = false;
					this.playArr[8].state = false;
					
					this.playArr[7].state = false;
					this.playArr[6].state = false;
					this.playArr[1].state = true;
					this.playArr[0].state = false;
					this.playArr[2].state = false;
					this.playArr[3].state = false;
					this.playArr[4].state = false;
					this.playArr[5].state = false;
					this.renovate()
					//this.$refs.dgRef.$on('dg', this.getDgArr);
				}
				if (item.value == '新全单固') {
					this.spfState = false;
					this.newdgState = true;
					this.getNowTime()
					this.dgState = false;
					this.bmState = false;
					this.bqcState = false;
					this.smallState = false;
					this.btspfState = false;
					this.btnewdgState = false;
					this.btdgState = false;
					this.playArr[8].state = false;
					
					this.playArr[7].state = false;
					this.playArr[6].state = false;
					this.playArr[2].state = true;
					this.playArr[1].state = false;
					this.playArr[0].state = false;
					this.playArr[3].state = false;
					this.playArr[4].state = false;
					this.playArr[5].state = false;
					this.renovate()
					//this.$refs.newdgRef.$on('newdg', this.getNewDgArr);
				}
				if (item.value == '大') {
					this.spfState = false;
					this.newdgState = false;
					this.dgState = false;
					this.bmState = true;
					this.bqcState = false;
					this.smallState = false;
					this.btspfState = false;
					this.btnewdgState = false;
					this.btdgState = false;
					this.playArr[8].state = false;
					
					this.playArr[7].state = false;
					this.playArr[6].state = false;
					this.playArr[3].state = true;
					this.playArr[1].state = false;
					this.playArr[2].state = false;
					this.playArr[0].state = false;
					this.playArr[4].state = false;
					this.playArr[5].state = false;
					this.getNowTime()
					this.renovate()
					//this.$refs.bsRef.$on('bm', this.getbmArr);
				}
				if (item.value == '小') {
					this.spfState = false;
					this.newdgState = false;
					this.dgState = false;
					this.bmState = false;
					this.bqcState = false;
					this.smallState = true;
					this.btspfState = false;
					this.btnewdgState = false;
					this.btdgState = false;
					this.playArr[8].state = false;
					
					this.playArr[7].state = false;
					this.playArr[6].state = false;
					this.playArr[3].state = false;
					this.playArr[1].state = false;
					this.playArr[2].state = false;
					this.playArr[0].state = false;
					this.playArr[4].state = true;
					this.playArr[5].state = false;
					this.getNowTime()
					this.renovate()
					//this.$refs.bsRef.$on('bm', this.getbmArr);
				}
				if (item.value == '半全场') {
					this.spfState = false;
					this.newdgState = false;
					this.dgState = false;
					this.bmState = false;
					this.bqcState = true;
					this.smallState = false;
					this.btspfState = false;
					this.btnewdgState = false;
					this.btdgState = false;
					this.playArr[8].state = false;
					
					this.playArr[7].state = false;
					this.playArr[6].state = false;
					this.playArr[5].state = true;
					this.playArr[1].state = false;
					this.playArr[2].state = false;
					this.playArr[0].state = false;
					this.playArr[3].state = false;
					this.playArr[4].state = false;
					this.getNowTime()
					this.renovate()
					//this.$refs.bsRef.$on('bm', this.getbmArr);
				}
				if (item.value == '篮球2串1') {
					this.spfState = false;
					this.newdgState = false;
					this.dgState = false;
					this.bmState = false;
					this.bqcState = false;
					this.smallState = false;
					this.btspfState = true;
					this.btnewdgState = false;
					this.btdgState = false;
					this.playArr[8].state = false;
					
					this.playArr[7].state = false;
					this.playArr[6].state = true;
					this.playArr[5].state = false;
					this.playArr[1].state = false;
					this.playArr[2].state = false;
					this.playArr[0].state = false;
					this.playArr[3].state = false;
					this.playArr[4].state = false;
					this.getNowTime()
					this.renovate()
					//this.$refs.bsRef.$on('bm', this.getbmArr);
				}
				if (item.value == '篮球单固') {
					this.spfState = false;
					this.newdgState = false;
					this.dgState = false;
					this.bmState = false;
					this.bqcState = false;
					this.smallState = false;
					this.btspfState = false;
					this.btdgState = true;
					this.btnewdgState = false;
					this.playArr[8].state = false;
					
					this.playArr[7].state = true;
					this.playArr[6].state = false;
					this.playArr[5].state = false;
					this.playArr[1].state = false;
					this.playArr[2].state = false;
					this.playArr[0].state = false;
					this.playArr[3].state = false;
					this.playArr[4].state = false;
					this.getNowTime()
					this.renovate()
					//this.$refs.bsRef.$on('bm', this.getbmArr);
				}
				if (item.value == '篮球新全单固') {
					this.spfState = false;
					this.newdgState = false;
					this.dgState = false;
					this.bmState = false;
					this.bqcState = false;
					this.smallState = false;
					this.btspfState = false;
					this.btdgState = false;
					this.btnewdgState = true;
					this.playArr[8].state = true;
					this.playArr[7].state = false;
					this.playArr[6].state = false;
					this.playArr[5].state = false;
					this.playArr[1].state = false;
					this.playArr[2].state = false;
					this.playArr[0].state = false;
					this.playArr[3].state = false;
					this.playArr[4].state = false;
					this.getNowTime()
					this.renovate()
					//this.$refs.bsRef.$on('bm', this.getbmArr);
				}
				//console.log(this.btspfState,121212)
				//console.log(item)
			},
			logout() {
				//清空token
				window.localStorage.clear()
				//编程式导航跳转到登录页
				this.$router.push('/login')
			},
			getNowTime() {
				var date = new Date();
				var time = date.getFullYear() + '-' + this.addZero(date.getMonth() + 1) + '-' + this.addZero(date
					.getDate()) + ' ' + this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this
					.addZero(date.getSeconds());
				this.newTime = time;
			},
			getEndime(item) {
				var date = new Date(item);
				//console.log(date,333)
				var time = date.getFullYear() + '-' + this.addZero(date.getMonth() + 1) + '-' + this.addZero(date
					.getDate()) + ' ' + this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this
					.addZero(date.getSeconds());
				return time
			},
			//根据自己的需求，看要不要在时间不大于10的时候在前面补0，如果需要直接this.addZero(date.getMinutes()),其它与之相同，如果不需要删掉addZero（）方法即可。
			//小于10的拼接上0字符串
			addZero(s) {
				return s < 10 ? ('0' + s) : s;
			},

		},
		async beforeMount() {
			//this.username = window.sessionStorage.getItem('user');
			//this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo') || '{}')
			//console.log(this.userInfo,'什么数据')
			this.username = window.localStorage.getItem('user');
			this.userInfo = JSON.parse(window.localStorage.getItem('userInfo') || '{}')
			this.daoqiTime = this.getEndime(Number(this.userInfo.endtime));
			//console.log(this.userInfo.endtime,this.daoqiTime,1212)
			this.getNowTime();
			this.isIcon = this.icon2;
			this.pdState()
			var dt=new Date();
			this.pdweek = this.weekDay[dt.getDay()]
			//console.log(this.pdweek)
			//this.updateData()
			//console.log(111)
			//this.getData()

		},
		
		
	}
</script>

<style scoped>
	.td-all{
		display: flex;
		margin: 20px;
		
		justify-content: space-between;
		
	}
	.td-st{
		width: 50px;
		height: 35px;
		line-height: 35px;
		text-align: center;
		font-size: 13px;
		background-color: #409EFF;
		color: #F8F8F8;
		border-radius: 5px;
	}
	.new-bb-false {
		margin-top: 5px;
		width: 100%;
		height: 30px;
		border: 1px solid #FF6C37;
		font-size: 12px;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #FF6C37;
	}
	
	.new-bb {
		margin-top: 5px;
		width: 100%;
		height: 30px;
		background-color: #FF6C37;
		font-size: 12px;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #F5F7FB;
	}
	/* #FF6C37 */
	/* .bd-st{
		width: 50px;
		height: 35px;
		line-height: 35px;
		text-align: center;
		font-size: 13px;
		background-color: #FF6C37 ;
		color: #F8F8F8;
		border-radius: 5px;
	}
	.bm-st{
		width: 50px;
		height: 35px;
		line-height: 35px;
		text-align: center;
		font-size: 13px;
		color:  #FF6C37;
		border: 1px solid  #FF6C37;
		border-radius: 5px;
	} */
	.tm-st{
		width: 50px;
		height: 35px;
		line-height: 35px;
		text-align: center;
		font-size: 13px;
		color: #409EFF;
		border: 1px solid #409EFF;
		border-radius: 5px;
	}
	.all-st{
		width: 50px;
		height: 35px;
		line-height: 35px;
		text-align: center;
		font-size: 13px;
		color: #409EFF;
		border: 1px solid #409EFF;
		border-radius: 5px;
	}
	.counter-all {
		border-top: 2px solid #0DA969;
		display: flex;
		justify-content: space-between;
		width: 99%;
		font-size: 10px;

		padding-top: 10px;
	}

	.jc-region {
		width: 350px;
	}

	.hg-one-region {
		width: 350px;
	}

	.hg-two-region {
		width: 350px;
	}

	.el-container {
		height: 100%;
	}

	.head-set {
		display: flex;
		justify-content: space-between;
	}

	.head-right {
		line-height: 60px;
		color: #409EFF;
		font-size: 25px;
	}

	.el-header {
		background-color: #F8F8F8;

	}

	.logout {
		margin-left: 20px;
		line-height: 35px;
		height: 35px;
		width: 100px;
		border: 1px solid #FF5722;
		text-align: center;
		margin-top: 10px;
		font-size: 13px;
		color: #FF5722;
		border-radius: 12px;
	}

	.el-main {
		background-color: #F8F8F8;
		margin: 0;
		padding: 0;
	}

	.el-aside {
		background-color: #F5F7FB;
	}

	.renovate {
		margin-top: 10px;
		margin-left: 20px;
		font-size: 10px;
	}

	.zd-renovate {
		height: 30px;
		margin-top: 10px;
		margin-left: 20px;
		font-size: 10px;
		margin-right: 20px;

		border-bottom: 1px solid #DCDFE6;
	}

	.el-select {
		width: 70px;
		margin-left: 37px;
	}

	.block {
		margin-top: 40px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.user {
		font-size: 8px;
		text-align: center;
	}

	.name {
		font-size: 12px;
		color: blue;
		font-weight: bold;
		text-align: center;
	}

	.counter {
		margin: 30px 0 10px 20px;
		width: 180px;
	}

	.input-arr {
		font-size: 12px;
		margin-left: 20px;
		margin-top: 10px;
	}

	.play-big {
		margin-top: 60px;
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 30px;
	}

	.set-play {
		width: 100%;
		height: 30px;
		background-color: #409EFF;
		font-size: 12px;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #F5F7FB;
	}

	.set-play-false {
		width: 100%;
		height: 30px;
		border: 1px solid #409EFF;
		background-color: #F5F7FB;
		font-size: 12px;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #409EFF;
	}

	.new-spf-false {
		margin-top: 5px;
		width: 100%;
		height: 30px;
		border: 1px solid #0DA969;
		font-size: 12px;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #0DA969;
	}

	.new-spf {
		margin-top: 5px;
		width: 100%;
		height: 30px;

		background-color: #0DA969;
		font-size: 12px;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #F5F7FB;
	}

	.dg-false {
		margin-top: 5px;
		width: 100%;
		height: 30px;
		border: 1px solid #409EFF;
		font-size: 12px;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #409EFF;
	}

	.dg {
		margin-top: 5px;
		width: 100%;
		height: 30px;
		border: 1px solid #409EFF;
		background-color: #409EFF;
		;
		font-size: 12px;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #F5F7FB;
	}

	.new-dg-false {
		margin-top: 5px;
		width: 100%;
		height: 30px;
		border: 1px solid #409EFF;
		font-size: 12px;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #409EFF;
	}

	.new-dg {
		margin-top: 5px;
		width: 100%;
		height: 30px;
		background-color: #409EFF;
		font-size: 12px;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #F5F7FB;
	}

	.big-false {
		margin-top: 5px;
		width: 100%;
		height: 30px;
		border: 2px solid #F0D6B0;
		font-size: 12px;
		font-weight: bold;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #F0D6B0;

	}

	.big {
		margin-top: 5px;
		width: 100%;
		height: 30px;

		background-color: #F0D6B0;
		font-size: 12px;
		font-weight: bold;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #F5F7FB;

	}

	.small {
		margin-top: 5px;
		width: 100%;
		height: 30px;
		border: 2px solid #F0D6B0;
		font-size: 12px;
		font-weight: bold;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		color: #F0D6B0;
	}

	.jc-bj {
		display: flex;
		justify-content: space-around;
	}

	.jg-bj {
		margin: 20px;
	}
	.jg-time-box{
		margin-top: 20px;
		width: 200px;
		font-size: 12px;
		margin-left: 20px;
		
	}
</style>
